// Body Level
.archive-memes, .single-memes{
	// .mobile-bottle-info, .ink-mask, .frame{
	// 	display: none;
	// }

	#header-main {
		position: fixed;

		width: 100vw;
		height: auto;
		z-index: 1000;
	}
	.mobile-bottle-info { display: none; }

	.border{
		&.top, &.bottom{
			width: 100vw;
		}
		&.left, &.right{
			height:100vh;
		}
	}
	#header-main{
		.block-bg-cover{
			display: none;
		}

    .velhotes-logo{
      opacity: 1;
      visibility: visible;

      .letters{
        display: block;
        &.add-transition {
          -webkit-transition: opacity 1s ease-in-out;
                  transition: opacity 1s ease-in-out;
        }
      }
      .logo-front{
        -webkit-transition: fill 1s ease-in-out;
                transition: fill 1s ease-in-out;
      }
      svg{
        opacity: 1;
        visibility: visible;
      }

      &.can-anime {
        -webkit-transition: -webkit-transform .25s ease-in-out;
                transition: transform .25s ease-in-out;
      }
    }

		.header-global{
			-webkit-transition: background .25s ease-in-out, box-shadow .25s ease-in-out, padding .25s ease-in-out;
      transition: background .25s ease-in-out, -webkit-box-shadow .25s ease-in-out, padding .25s ease-in-out;

			#create-meme-btn{
				#smile-container{
					svg{
						path{
							stroke: #000;
						}
					}
					#eye{
						border: 2px solid $black;
					}
				}

				h2,
				.char {
					opacity: 0;
					visibility: hidden;
				}
			}

      &.active {
        background: rgba($white, .97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
        padding: 23px 53px;

        .velhotes-logo {
          transform: scale(.85);
          -webkit-transform: scale(.85);
        }
      }
		}


		&.active {
			.velhotes-logo{
				.logo-front{
					fill: $black;
				}
				.letters{
					opacity: 0;
				}
			}
		}

	}
	footer{
		padding-top: 150px;
		h2, h3, form,{
			display: none;
		}
	}
}


// Page Level
.archive-memes-page, .single-memes-page {
	.content-main-wrapper{
		padding: 100px 0.625rem calc(0.625rem * 2);
		.grid-loader{
			position: absolute;
			bottom: 175px;
			left: 50%;

			opacity: 0;
			visibility: 0;
		}
		.meme-grid {
			width: 100%;
			max-width: 100vw;
			overflow: hidden;
			margin: 0 auto;
			.meme-container {
				width: 20%;
				height: 0;
				padding-top: 20%;
				float: left;
				overflow: hidden;
				position: relative;


				opacity: 0;
				visibility: hidden;

				&.create-new {
					pointer-events: none;

					.create-new-wrapper {
						position: absolute;
						padding: 15px;
				    top: 0;
				    left: 0;
				    width: 100%;
				    // height: 90.5%;
				    overflow: hidden;
						.inner-wrapper {
							position: relative;
							height: 100%;
							padding: 60px;

					    border-top: 3px solid #fff2db;
							border-bottom: 3px solid #e4cea4;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							background-position: 0 0, 100% 0;
							background-repeat: no-repeat;
							-webkit-background-size: 3px 100%;
							-moz-background-size: 3px 100%;
							background-size: 3px 100%;
							background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
							background-image: -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%), linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%);

					    h3 {
								font-family: 'velhotes-heavy';
						    font-size: calc(40px + (60 - 40) * (100vw - 414px) / (1600 - 414));
						    line-height: 0.85;
						    letter-spacing: -1.25px;
						    text-transform: uppercase;
						    color: #000000;
						    margin-bottom: 20px;
							}
							h4 {
								font-family: 'velhotes-regular';
						    font-size: 26px;
						    color: #a7a3a3;
						    margin-bottom: 40px;
							}

							.create-meme-btn {
								display: flex;
								align-items: center;

								position: absolute;
	    						bottom: 60px;
	    						left: 60px;
							    padding: 20px 50px 20px 30px;
							    // margin-bottom: 20px;

							    text-align: left;
							    background-color: #000000;
							    color: #ffffff;

							    font-family: 'velhotes-roman';
							    font-size: 15px;
							    letter-spacing: 2.5px;
							    text-transform: uppercase;

							    pointer-events: all;
							    .smile-container{
							    	position: relative;

							    	width: 27px;
							    	height: 25px;
							    	margin-left: 55px;

							    	svg{
							    		position: absolute;
							    		bottom: 0;
							    		left: 0;
							    		path{
							    			fill:none;
							    			stroke:#fff;
							    			stroke-miterlimit:10;
							    			stroke-width:3px;
							    		}
							    	}
							    	.eye{
							    		position: absolute;
							    		top: 0;
							    		left: 0;

							    		width: 10px;
							    		height: 10px;

							    		transition: 1s transform ease(out-expo);
							    		-webkit-transition: 1s transform ease(out-expo);

							    		border: 2px solid $white;
							    		border-radius: 10px;
							    	}
							    	.wink-container{
							    		position: absolute;
							    		top: 0;
							    		right: 0;

							    		width: 8px;
							    		height: 8px;
							    		span{
							    			position: absolute;
							    			top: 50%;
							    			left: 0;

							    			width: 100%;
							    			height: 2px;

							    			transform: translateY(-50%);
							    			-webkit-transform: translateY(-50%);

							    			transition: 1s transform ease(out-expo);
							    			-webkit-transition: 1s transform ease(out-expo);

							    			background: $white;
							    			&:first-of-type{
							    				top: 50%;
							    				transform: translateY(-50%) rotate(-35deg);
							    				-webkit-transform: translateY(-50%) rotate(-35deg);
							    				transform-origin: center left;
							    				-webkit-transform-origin: center left;
							    			}
							    			&:last-of-type{
							    				top: 50%;
							    				-webkit-transform: translateY(-50%) rotate(35deg);
							    				transform: translateY(-50%) rotate(35deg);
							    				transform-origin: center left;
							    				-webkit-transform-origin: center left;
							    			}
							    		}
							    	}
							    }
							}
						}
					}
				}

				&:after{
					content: '';
					position: absolute;
					display: block;
					z-index: 2;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					border: 15px solid $white;
				}
				&  > .author {
					width: 100%;
					position: absolute;
					bottom: 15px;
					left:0;
					padding-top: 15px;
					padding-left: 15px;
					background-color: #fff;
					z-index: 2;
					-webkit-transition: all .20s ease-in-out;
						transition: all .20s ease-in-out;
					&	>  p {
						font-family: 'velhotes-roman';
						font-size: 19px;
						color: $black;
						letter-spacing: -1px;
						margin: 5px 0 0 50px;
						& > span {
							width: 100%;
							float: left;
							font-family: 'velhotes-regular';
							font-size: 15px;
							color: rgba($black, 0.4);
							letter-spacing: 0px;
						}
					}
					& > .thumb {
						width: 40px;
						height: 40px;
						overflow: hidden;
						float: left;
						border-radius: 50%;
            border: 1px solid #efefef;
					}
				}
				.meme-image{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 93%;
					overflow: hidden;
					.overlay{
						position: absolute;
						top: 15px;
						left: 15px;

						width: calc(100% - 30px);
						height: calc(100% - 60px);
						z-index: 2;

						background: rgba($black, 0);

						transition: background 1s ease(out-expo);
						-webkit-transition: background 1s ease(out-expo);
						.touch-open{
							font-family: 'velhotes-roman';
							font-size: 16px;
							position: absolute;
							bottom: 15%;
							left: 0;
							right: 0;
							margin: 0 auto;
							text-align: center;
							transform: scale(0.8);
							-webkit-transform: scale(0.8);
							letter-spacing: 4px;
							transition: all 1s ease(out-expo);
							-webkit-transition: all 1s ease(out-expo);
							opacity: 0;
							visibility: hidden;
							color: $white;
						}
						.meme-frame{
							position: absolute;
							top: 0;
							left: 0;
							z-index: 2;

							width: 100%;
							height: 100%;

							transform: scale(1.1);
							-webkit-transform: scale(1.1);

							transition: transform 1s ease(out-expo);
							-webkit-transition: transform 1s ease(out-expo);

							border-top: 2px solid #fff2db;
							border-bottom: 2px solid #fff2db;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							background-position: 0 0, 100% 0;
							background-repeat: no-repeat;
							-webkit-background-size: 2px 100%;
							-moz-background-size: 2px 100%;
							background-size: 2px 100%;
							background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
							background-image: -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%), -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
							background-image: linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%), linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%);

						}
						.shares-container{
							position: absolute;
							top: 50%;
							left: 50%;
							z-index: 3;

							width: 150px;
							height: 150px;

							transform: translate(-50%, -50%) scale(0.85);
							-webkit-transform: translate(-50%, -50%) scale(0.85);
							opacity: 0;
							visibility: hidden;

							transition: all 1s ease(out-expo);
							-webkit-transition: all 1s ease(out-expo);

							// padding-top: 20px;

							background: $white;
							border-radius: 50%;
							text-align: center;
							// width: 160px;
						    display: flex;
						    // height: 160px;
						    align-items: center;
						    justify-content: center;
						    flex-direction: column;
							span{
								font-family: 'velhotes-roman';
								font-size: calc(12px + (40 - 12) * (100vw - 414px) / (1600 - 414));
								color: $black;
							}
							h2{
								font-family: 'velhotes-regular';
								font-size: calc(10px + (20 - 10) * (100vw - 414px) / (1600 - 414));
								color: $black;
							}
							ul{
								margin-top: 18px;
								li{
									display: inline;
									cursor: pointer;
									svg{
										width: 17px;
										height: 17px;
										path{
											fill: #80000a;
										}
									}
									&:last-of-type{
										margin-left: 23px;
									}
								}
							}
						}
					}
				}
				&:nth-child(11n+1){
					width: 40%;
					padding-top: 40%;
					&:hover{
						.touch-open{
							opacity: 1;
							visibility: visible;
						}
					}
					.meme-image{
						.overlay{
							height: calc(100% - 30px);
						}
						.shares-container{
							width: 160px;
							height: 160px;
						}
						.block-bg-cover{
							.element-cover{
								height: calc(100% - 15px);
							}
						}
					}
				}
				&:nth-child(11n+6) {
					width: 40%;
					padding-top: 40%;
					&:hover{
						.touch-open{
							opacity: 1;
							visibility: visible;
						}
					}
					.meme-image{
						.overlay{
							height: calc(100% - 30px);
						}
						.shares-container{
							width: 160px;
							height: 160px;
						}
						.block-bg-cover{
							.element-cover{
								height: calc(100% - 15px);
							}
						}
					}
				}
				&:nth-child(11n+7){
					margin-top: -20%;
				}
				&:nth-child(11n+8) {
					width: 40%;
					padding-top: 40%;
					margin-top: -20%;
					margin-left: 20%;
					&:hover{
						.touch-open{
							opacity: 1;
							visibility: visible;
						}
					}
					.meme-image{
						.overlay{
							height: calc(100% - 30px);
						}
						.shares-container{
							width: 160px;
							height: 160px;
						}
						.block-bg-cover{
							.element-cover{
								height: calc(100% - 15px);
							}
						}
					}
				}
				&:nth-child(11n+9) {
					margin-left:-60%;
				}
				&:hover{
					.meme-image{
						.overlay{
							background: rgba($black, 0.6);
							.meme-frame{
								transform: scale(0.8);
								-webkit-transform: scale(0.8);
								opacity: 1;
							}
							.touch-open{
								transform: scale(1);
								-webkit-transform: scale(1);
								opacity: 1;
								letter-spacing: 0;
							}
							.shares-container{
								transform: translate(-50%, -50%) scale(1);
								-webkit-transform: translate(-50%, -50%) scale(1);
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}
		}
	}
}

.single-denuncia-lightbox{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);

	z-index: 11;
	opacity: 0;
	visibility: hidden;
	background-color: rgba($black, 0.8);

	.single-denuncia-meme{
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 70px 80px 130px 80px;

		background-color: $white;
		box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);
		-webkit-box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);

		-webkit-transform: translate3d(0,-50%,0);
			transform: translate3d(0,-50%,0);

		z-index: 10;

		h2 {
	        font-family: 'velhotes-heavy';
	        font-size: calc(40px + (60 - 40) * (100vw - 414px) / (1600 - 414));
	        line-height: 0.85;
	        letter-spacing: -1.25px;
	        text-transform: uppercase;
	        color: $black;
	        margin-bottom: 20px;

	        font-feature-settings: "salt";
	        -webkit-font-feature-settings: "salt";
	        -o-font-feature-settings: "salt";
	        -moz-font-feature-settings: "salt";
	        -ms-font-feature-settings: "salt";
		}

		h4 {
			font-family: 'velhotes-regular';
			font-size: 26px;
			color: #a7a3a3;
			margin-bottom: 40px;
		}

		.close-denuncia{
			position: absolute;
			top: -30px;
		  	right: -30px;
		  	width: 60px;
		  	height: 60px;
		  	z-index: 1;

			border-radius: 60px;
			text-indent: -9999px;

			background-color: #ffffff;

			-webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
			   -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
							box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
			&:hover{
				&:before{
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
				}
				&:after{
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}
			}
			&:before, &:after{
				transition: 1s transform ease(out-expo);
				-webkit-transition: 1s transform ease(out-expo);
				will-change: transform;
			}
			&:before {
				content: '';
				position: absolute;
				top: 29px;
				left: 17px;
				width: 27px;
				height: 1px;

				background-color: #000000;

				transform: rotate(-45deg) translate3D(0,0,0);
				-webkit-transform: rotate(-45deg) translate3D(0,0,0);
			}
			&:after {
				content: '';
				position: absolute;
				top: 29px;
				left: 17px;
				width: 27px;
				height: 1px;

				background-color: #000000;

				transform: rotate(45deg) translate3D(0,0,0);
				-webkit-transform: rotate(45deg) translate3D(0,0,0);
			}
		}
		.send-denuncia{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			width: 100%;

			font-family: 'velhotes-heavy';
			font-size: calc(12px + (20 - 12) * (100vw - 414px) / (1600 - 414));
			text-transform: uppercase;

			padding: 30px 0;

			color: $white;
			background: #3adb51;
			display: block;
			cursor: pointer;

			.lds-eclipse {
				position: absolute;
		    top: 16px;
		    left: calc(50% - 100px);
		    width: 25px;

		    opacity: 0;
			}
		}
	}
}

.single-meme-lightbox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;

	background-color: rgba(#333230, .9);

	-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);

	z-index: 11;

	opacity: 0;
	visibility: hidden;

	.single-meme-outside-hitzone {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		z-index: -1;
	}

	.single-meme-wrapper {
		position: relative;
		height: 100%;

		pointer-events: none;

		.denunciar-meme{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			font-family: 'velhotes-regular';
			text-transform: uppercase;
			letter-spacing: 1px;
			color: $black;
			display: block;
		}

		.close-single-meme {
			position: absolute;
			top: -30px;
		  	right: -30px;
		  	width: 60px;
		  	height: 60px;
		  	z-index: 1;

			border-radius: 60px;
			text-indent: -9999px;

			background-color: #ffffff;

			-webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
			   -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
							box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
			&:hover{
				&:before{
					transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
				}
				&:after{
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}
			}
			&:before, &:after{
				transition: 1s transform ease(out-expo);
				-webkit-transition: 1s transform ease(out-expo);
				will-change: transform;
			}
			&:before {
				content: '';
				position: absolute;
				top: 29px;
				left: 17px;
				width: 27px;
				height: 1px;

				background-color: #000000;

				transform: rotate(-45deg) translate3D(0,0,0);
				-webkit-transform: rotate(-45deg) translate3D(0,0,0);
			}
			&:after {
				content: '';
				position: absolute;
				top: 29px;
				left: 17px;
				width: 27px;
				height: 1px;

				background-color: #000000;

				transform: rotate(45deg) translate3D(0,0,0);
				-webkit-transform: rotate(45deg) translate3D(0,0,0);
			}
		}

		.next-meme {
			position: absolute;
			width: 32px;
	    height: 48px;
	    padding: 5px;
			right: -50px;
			top: calc(50% - 19px);

			svg {

				-webkit-transition: 1s transform ease(out-expo);
								transition: 1s transform ease(out-expo);
				path {
					fill:none;
					stroke:#ffffff;
					stroke-width:3;
					stroke-linecap:round;
					stroke-linejoin:round;
					stroke-miterlimit:10;
				}
			}

			&:hover{
				svg {
					-webkit-transform: translateX(10px);
									transform: translateX(10px);
				}


			}
		}
		.prev-meme {
			position: absolute;
			width: 32px;
			height: 48px;
	    padding: 5px;
			left: -50px;
			top: calc(50% - 19px);

			svg {

				-webkit-transition: 1s transform ease(out-expo);
								transition: 1s transform ease(out-expo);

				path {
					fill:none;
					stroke:#ffffff;
					stroke-width:3;
					stroke-linecap:round;
					stroke-linejoin:round;
					stroke-miterlimit:10;
				}
			}

			&:hover{
				svg {
					-webkit-transform: translateX(-10px);
									transform: translateX(-10px);
				}
			}
		}

		.single-meme-container {
			position: relative;
			background-color: #ffffff;
			box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);
			-webkit-box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);

			pointer-events: all;

			.image-wrapper {
				position: relative;
				padding: 80px 60px;

				overflow: hidden;

				perspective: 1000px;
				-webkit-perspective: 1000px;

				.single-meme-image {
					position: relative;
					border: 7px solid #fff4dc;
				}

				.single-corda{
					position: absolute;
					top: -70px;
					width: 80px;
					overflow: hidden;

					backface-visibility: hidden;
					-webkit-backface-visibility: hidden;

					z-index: 1;
				}

				.corda-left{
					left: 80px;
				}

				.corda-right{
					right: 80px;
				}
			}

			.single-meme-text-wrapper {
				position: relative;
				padding: 60px;

				.denunciar-meme{
					position: absolute;
					bottom: 25px;
					left: 0;
					right: 0;
					margin: 0 auto;
					text-align: center;
					h3{
						font-family: 'velhotes-bold-name';
						font-size: 10px;
						cursor: pointer;
						display: inline-block;
						color: rgba($black, 0.2);
						border-bottom: 1px solid rgba($black, 0.2);
					}
				}

				.single-meme-text-header {
					position: relative;
					height: 15%;

					h3 {
						font-family: 'velhotes-roman';
						font-size: 25px;
						color: #261919;
					}

					h4 {
						font-family: 'velhotes-roman';
						font-size: 17px;
						color: #261919;
					}

					p.date {
						font-family: 'velhotes-regular';
						font-size: 15px;
						line-height: 18px;
						color: rgba(#261919, .4);
					}

					.avatar-image {
						border-radius: 100%;
            			border: 1px solid #efefef;
					}
				}//.single-meme-text-header


				.single-meme-text-content {
					position: relative;
					height: 50%;

					margin: 20px 0px;
					padding: 40px 0px;

					border-top: 1px solid rgba(#261919, .1);
					border-bottom: 1px solid rgba(#261919, .1);

					p {
						margin-bottom: 30px;

						font-family: 'velhotes-roman';
						font-size: 22px;
						color: rgba(#261919, 1);
						// text-transform: capitalize;

						span.title {
							font-family: 'velhotes-bold-name';
							font-size: 13px;
							text-transform: uppercase;
							color: rgba(#261919, .4);
							text-align: right;
							line-height: 28px;
						}
					}
				}//.single-meme-text-content

				.single-meme-text-footer {
					position: relative;
					height: 30%;

					p {
						margin-bottom: 20px;

						font-family: 'velhotes-roman';
						font-size: 50px;
						color: rgba(#261919, 1);
						text-transform: capitalize;

						span {
							display: inline-block;
							margin-right: 20px;
							width: 60px;

							font-family: 'velhotes-bold-name';
							font-size: 13px;
							text-transform: uppercase;
							color: rgba(#261919, .4);
							text-align: right;
						}
					}

					.fb-like {
						position: absolute;
						height: 56px;
						margin-left: 11px;
    				bottom: 0px;
					}
				}
			}

		}
	}
}
// Media Queries
@include screen($medium, $xxlarge, $orientation: false) {

}

@include screen($xlarge, 2100px, $orientation: false) {  // --- MacBook Pro 15'(.ie) ---

}

@include min-screen($medium) {

}

@include screen(1024px, 1366px, $orientation: portrait) { // --- IPAD Pro ---

}

@include ipad($orientation: landscape) {

}

@include ipad($orientation: portrait) {

}

@include max-screen($xlarge) {
	.archive-memes-page, .single-memes-page {
		.content-main-wrapper{
			padding: 85px 0.625rem calc(0.625rem * 2);
		}
	}
	.archive-memes-page .content-main-wrapper .meme-grid .meme-container:nth-child(11n+1) .meme-image .block-bg-cover .element-cover, .single-memes-page .content-main-wrapper .meme-grid .meme-container:nth-child(11n+1) .meme-image .block-bg-cover .element-cover{
		height: calc(100% - 30px);
	}
}

@include max-screen($medium) {
	.archive-memes-page{
		.content-main-wrapper{
			.meme-grid{
				.meme-container:nth-child(11n+2) .author,
				.meme-container:nth-child(11n+3) .author,
				.meme-container:nth-child(11n+4) .author,
				.meme-container:nth-child(11n+5) .author,
				.meme-container:nth-child(11n+7) .author,
				.meme-container:nth-child(11n+9) .author,
				.meme-container:nth-child(11n+10) .author,
				.meme-container:nth-child(11n+11) .author
				{
					opacity: 0;
				}
			}
		}
	}

  .single-meme-lightbox {
    .single-meme-wrapper {
      .single-meme-container {
        .single-meme-text-wrapper {
          padding: 40px;
        }
      }
    }
  }
}

@include max-screen($small) { // --- iPad portrait ---
	.archive-memes-page, .single-memes-page{
		.content-main-wrapper{
			.meme-grid{
				.meme-container{
					float: none;
					width: 100%;
					height: 0;
					padding-top: 100%;
					margin: 0;
					border-bottom: 0 none;
					&:nth-child(11n+1), &:nth-child(11n+4), &:nth-child(11n+8), &:nth-child(11n+11){
						width: 100%;
						height: 0;
						padding-top: 100%;
						margin: 0;
						float: left;
					}
					&:nth-child(11n+2), &:nth-child(11n+3), &:nth-child(11n+6), &:nth-child(11n+7), &:nth-child(11n+9), &:nth-child(11n+10){
						width: 50%;
						height: 0;
						padding-top: 50%;
						margin: 0;
						float: left;
					}
					&:nth-child(11n+2) .author, &:nth-child(11n+3) .author, &:nth-child(11n+4) .author, &:nth-child(11n+5) .author, &:nth-child(11n+7) .author, &:nth-child(11n+9) .author, &:nth-child(11n+10) .author, &:nth-child(11n+11) .author{
						opacity: 1;
					}
				}
			}
		}
	}

  .single-meme-lightbox {
    .single-meme-wrapper {
      .single-meme-container {
        margin: 50px 0;

        .single-meme-text-wrapper {
          .single-meme-text-header {
            height: auto;
          }
        }
      }
    }
  }
}

@include max-screen($xsmall) { // --- iPhone 6/6S/7 and plus versions portrait ---
  .archive-memes,
  .single-memes {
    #header-main {
      padding: 0;
      .header-global{
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0;

        .user-container:not(.mobile) {
          .user-image {
            margin: 0;
          }

          h2 { display: none; }
          .logout-btn { display: none; }
        }

        &.active {
          padding: 30px 0;
        }
      }
    }

    footer h4 {
      margin-top: 0;
    }

    .content-main-wrapper {
      margin-top: 120px;
      padding: 25px 0.625rem calc(0.625rem * 2);
      .filter-container {
        display: none;
      }
    }
  }

  .archive-memes-page,
  .single-memes-page {
    .content-main-wrapper {
      .meme-grid {
        .meme-container {
          .meme-image {
            .overlay {
              display: none;
            }
          }

          > .author > p {
            margin: 0px 0 0 40px;
          }

          > .author > .thumb {
            width: 32px;
            height: 32px;
          }

          &.create-new {
            padding-top: calc(100% + 20px);
            .create-new-wrapper {
              height: 100%;
              .inner-wrapper {
                padding: 30px;
                height: 100%;

                h4 br { display: none; }
                h4 { margin-bottom: 25px; }

                .create-meme-btn {
                  position: relative;
                  bottom: auto;
                  left: auto;
                  margin: 0;

                  .smile-container {
                    margin-left: 0;
                    left: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .single-meme-lightbox {
    overflow-x: hidden;
    .single-meme-wrapper {
      height: auto;
      overflow-y: scroll;
      .close-single-meme {
        top: 20px;
        right: 20px;
        z-index: 2;
        width: 50px;
        height: 50px;

        &:before {
          top: 25px;
          left: 12px;
        }

        &:after {
          top: 25px;
          left: 12px;
        }
      }

      .prev-meme {
        left: 4px;
        top: 175px;
        z-index: 1;
      }

      .next-meme {
        right: 4px;
        top: 175px;
        z-index: 1;
      }

      .single-meme-container {
        margin: 0;

        .image-wrapper {
          height: 390px;
          padding: 0;

          .single-meme-image {
            top: 50%;
            max-width: calc(100% - 80px);
            margin: 0 auto;
            transform: translatey(-50%);
            -webkit-transform: translateY(-50%);
          }

          .corda-left {
            left: 40px;
            top: -75px;
            width: 70px;
          }

          .corda-right {
            right: 40px;
            width: 70px;
            top: -75px;
          }
        }

        .single-meme-text-wrapper {
          padding: 40px 30px;

          .single-meme-text-footer {
            .fb-like {
              margin-left: 8px;
              height: 42px;
              position: relative;
              display: block;
              margin-top: 30px;
            }
          }

          .denunciar-meme {
            position: relative;
            bottom: auto;
            height: 100px;
          }
        }
      }
    }
  }

  .single-denuncia-lightbox {
    position: fixed;

    .single-denuncia-meme {
      padding: 40px 40px 0 40px;

      h2 { padding-right: 50px; }

      .send-denuncia {
        position: relative;
        bottom: auto;
        left: -40px;
        width: calc(100% + 80px);
        font-size: 18px !important;

        .lds-eclipse {
          width: 30px;
        }
      }

      .close-denuncia {
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;

        &:before {
          top: 25px;
          left: 12px;
        }

        &:after {
          top: 25px;
          left: 12px;
        }
      }
    }
  }
}

@include max-screen($xxsmall) { // --- iPhone 4/4S/5/5S portrait ---

}