// Body Level
.legal{
	#header-main {
    position: fixed;

    width: 100vw;
    height: auto;
    z-index: 1000;
  }
  .mobile-bottle-info { display: none; }

  .border{
    &.top, &.bottom{
      width: 100vw;
    }
    &.left, &.right{
      height:100vh;
    }
  }
  #header-main{
    .block-bg-cover{
      display: none;
    }

    .velhotes-logo{
      opacity: 1;
      visibility: visible;

      .letters{
        display: block;
        &.add-transition {
          -webkit-transition: opacity 1s ease-in-out;
                  transition: opacity 1s ease-in-out;
        }
      }
      .logo-front{
        -webkit-transition: fill 1s ease-in-out;
                transition: fill 1s ease-in-out;
      }
      svg{
        opacity: 1;
        visibility: visible;
      }

      &.can-anime {
        -webkit-transition: -webkit-transform .25s ease-in-out;
                transition: transform .25s ease-in-out;
      }
    }

    .header-global{
      -webkit-transition: background .25s ease-in-out, box-shadow .25s ease-in-out, padding .25s ease-in-out;
      transition: background .25s ease-in-out, -webkit-box-shadow .25s ease-in-out, padding .25s ease-in-out;

      #create-meme-btn{
        #smile-container{
          svg{
            path{
              stroke: #000;
            }
          }
          #eye{
            border: 2px solid $black;
          }
        }

        h2,
        .char {
          opacity: 1;
          visibility: visible;
        }
      }

      #cria-o-meme { display: none; }

      &.active {
        background: rgba($white, .97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
        padding: 23px 53px;

        .velhotes-logo {
          transform: scale(.85);
          -webkit-transform: scale(.85);
        }
      }
    }


    &.active {
      .velhotes-logo{
        .logo-front{
          fill: $black;
        }
        .letters{
          opacity: 0;
        }
      }
    }
  }
}


// Page Level
.legal-page {
	.content-main-wrapper{
		padding-top: 200px;
		color: $black;
		h2{
			font-family: 'velhotes-title';
			font-size: 55px;
			margin-bottom: 55px;
			text-transform: uppercase;
			p{
				text-transform: initial;
			}
		}
		h3{
			font-family: 'velhotes-regular';
			font-size: 32px;
			color: #e4cea3;
			margin-top: 60px;
			margin-bottom: 40px;
		}
		p, h4, span, li{
			font-family: 'velhotes-regular';
			font-size: 21px;
			line-height: 1.3;
			color: $black;
			margin-bottom: 15px;
			strong{
				font-family: 'velhotes-regular';
				font-size: 21px;
				line-height: 1.2;
				color: #e4cea3;
			}
			a{
				color: $black;
			}
		}
		span{
			display: block;
			margin-bottom: 100px;
		}
		h5{
			font-family: 'velhotes-regular';
			font-size: 26px;
			color: $black;
		}
		h4{
			margin-bottom: 100px;
		}
		ul{
			margin-top: 25px;
			margin-left: 70px;
			li{
				list-style-type: disc;
				line-height: 1.2;
				margin-bottom: 20px;
			}
		}
		table{
			border-collapse:collapse;
			border-spacing:0;
			border-color:#e4cea3;
			margin:35px auto;
		 	td{
				font-family:'velhotes-regular';
				font-size:14px;
				padding:10px 5px;
				border-style:solid;
				border-width:1px;
				overflow:hidden;
				word-break:normal;
				border-color:#e4cea3;
				color:$black;
				background-color:$white;
			}
			th{
				font-family:'velhotes-regular';
				font-size:14px;
				font-weight:normal;
				padding:10px 10px;
				border-style:solid;
				border-width:1px;
				overflow:hidden;
				word-break:normal;
				border-color:#e4cea3;
				color: #e4cea3;
			}
			.table-text{
				font-family: 'velhotes-regular';
				font-size: 17px;
				background-color:transparent;
				color:$black;
				text-align:center;
				vertical-align:top;
				a{
					color: $black;
				}
			}
			.table-title{
				font-family:'velhotes-bold-name';
				background-color:transparent;
				font-size: 21px;
				color:$black;
				text-align:center;
				vertical-align:top;
			}
		}
	}
	footer{
		margin-top: 150px;
	}
}

// Media Queries
@include screen($medium, $xxlarge, $orientation: false) {

}

@include screen($xlarge, 2100px, $orientation: false) {  // --- MacBook Pro 15'(.ie) ---

}

@include min-screen($medium) {

}

@include screen(1024px, 1366px, $orientation: portrait) { // --- IPAD Pro ---

}

@include ipad($orientation: landscape) {

}

@include ipad($orientation: portrait) {

}

@include max-screen($xlarge) {

}

@include max-screen($medium) {

}

@include max-screen($small) { // --- iPad portrait ---

}

@include max-screen($xsmall) { // --- iPhone 6/6S/7 and plus versions portrait ---
	.legal{
		#header-main {
      .header-global {
        #create-meme-btn {
          h2#cria-o-meme,
          h2#memes-velhotes {
            display: none;
          }
        }
        &.active {
          padding: 30px 0 23px;
        }
      }
    }
	}
	.legal-page {
		.content-main-wrapper{
      padding-top: 120px;
			h2{
				font-size: 35px;
			}
			ul{
				margin-left: 25px;
			}
			table{
				overflow-x: auto;
				width: 100%;
				display: inline-block;
			}
		}
	}
}

@include max-screen($xxsmall) { // --- iPhone 4/4S/5/5S portrait ---

}