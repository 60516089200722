// Body Level

.home {
  .donations-modal {
    position: absolute;
    left: 5px;
    bottom: 50px;
    width: 550px;
    height: auto;
    background-color: #fff;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);

    z-index: 10;

    img {
    }

    .close {
      display: none;
      position: absolute;
      top: 20px;
      right: 20px;
      transform: scale(1.5);
      -webkit-transform: scale(1.5);
    }

    @include max-screen($medium) {
      max-width: 420px;
      width: 100%;
      // padding: 30px;
      bottom: 20px;
    }

    // @include max-screen($small) {
    //   bottom: 40px;
    //   max-width: 550px;
    //   padding: 55px;
    // }

    @include max-screen($xsmall) {
      width: 80vw;
      // padding: 40px 20px 20px;
      bottom: 20px;
    }

    h3 {
      position: absolute;
      font-family: "velhotes-heavy";
      font-size: 40px;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: #fff8e9;
      font-feature-settings: "salt" on;

      max-width: 80%;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50px;

      @include max-screen($medium) {
        font-size: 28px;
      }

      @include max-screen($xsmall) {
        font-size: 20px;
        top: 30px;
      }

      @include max-screen($xxsmall) {
        font-size: 18px;
        top: 20px;
        max-width: 85%;
      }
    }
    p {
      font-family: "velhotes-regular";
      font-size: 21px;
      letter-spacing: -0.02em;
      color: #a7a3a3;
      width: 65%;
      margin-top: 20px;

      @include max-screen($medium) {
        width: 100%;
      }

      span {
        color: #ed1b24;
      }
    }

    .btn-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      margin-top: 40px;
      position: absolute;
      bottom: 30px;
      left: 10px;

      @include max-screen($xsmall) {
        margin-top: 30px;
        flex-direction: column;
        left: 7px;
      }

      a {
        background-color: #000;
        width: 240px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: "velhotes-regular";
        font-size: 15px;
        letter-spacing: 0.13em;
        color: #fff;
        text-transform: uppercase;
        font-feature-settings: "salt" on;

        transition: background-color 0.25s ease-out, color 0.25s ease-out;

        &:hover {
          background-color: #fff8e9;
          color: #000;

          svg {
            path {
              fill: #000;
            }
          }
        }

        @include max-screen($medium) {
          font-size: 14px;
        }

        @include max-screen($xsmall) {
          // width: 100%;
          width: 220px;
          height: 50px;
        }

        @include max-screen($xxsmall) {
          // width: 100%;
          width: 180px;
          height: 40px;
          font-size: 12px;
        }

        svg {
          margin-left: 30px;

          path {
            fill: white;

            transition: fill 0.25s ease-out;
          }

          @include max-screen($medium) {
            margin-left: 20px;
          }
        }
      }

      .total-wrapper {
        display: inline-block;
        margin-left: 40px;

        @include max-screen($xsmall) {
          margin-left: 0;
          margin-top: 20px;
          text-align: center;
          display: none;
        }
        p {
          width: 100%;
          font-family: "velhotes-bold-name";
          font-size: 13px;
          line-height: 14px;
          letter-spacing: 0.04em;
          color: #b2afaf;
          text-transform: uppercase;
          margin-top: 0;
        }
        .amount-wrapper {
          margin-top: 15px;
          .euro {
            font-family: "velhotes-heavy";
            font-size: 31px;
            color: #000000;
          }
          .total-value {
            font-family: "velhotes-heavy";
            font-size: 61px;
            line-height: 0px;
            color: #000000;
            white-space: nowrap;

            @include max-screen($medium) {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  .mobile-btn-wrapper {
    text-align: center;
  }
  .buy-now-btn {
    display: inline-block;
    position: relative;

    margin-top: 20px;
    padding: 15px 40px 15px 60px;
    pointer-events: all;
    font-family: "velhotes-bold";
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #020202;
    border: 2px solid #020202;

    transition: 0.4s color cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: 0.4s color cubic-bezier(0.19, 1, 0.22, 1);

    span {
      position: relative;
      left: 10px;
    }

    &[data-color="tawny"] {
      color: $tawny;
      border: 2px solid $tawny;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $tawny;
      }

      svg {
        path {
          fill: $tawny;
        }
        .first {
          fill: $tawny;
        }
        .second {
          stroke: $tawny;
        }
      }
    }
    &[data-color="special"] {
      color: $special;
      border: 2px solid $special;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $special;
      }

      svg {
        path {
          fill: $special;
        }
        .first {
          fill: $special;
        }
        .second {
          stroke: $special;
        }
      }
    }
    &[data-color="tenYears"] {
      color: $tenYears;
      border: 2px solid $tenYears;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $tenYears;
      }

      svg {
        path {
          fill: $tenYears;
        }
        .first {
          fill: $tenYears;
        }
        .second {
          stroke: $tenYears;
        }
      }
    }
    &[data-color="ruby"] {
      color: $ruby;
      border: 2px solid $ruby;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $ruby;
      }

      svg {
        path {
          fill: $ruby;
        }
        .first {
          fill: $ruby;
        }
        .second {
          stroke: $ruby;
        }
      }
    }
    &[data-color="lbv"] {
      color: $lbv;
      border: 2px solid $lbv;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $lbv;
      }

      svg {
        path {
          fill: $lbv;
        }
        .first {
          fill: $lbv;
        }
        .second {
          stroke: $lbv;
        }
      }
    }
    &[data-color="whiteWine"] {
      color: $whiteWine;
      border: 2px solid $whiteWine;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $whiteWine;
      }

      svg {
        path {
          fill: $whiteWine;
        }
        .first {
          fill: $whiteWine;
        }
        .second {
          stroke: $whiteWine;
        }
      }
    }
    &[data-color="lagrima"] {
      color: $lagrima;
      border: 2px solid $lagrima;

      &:hover {
        color: #ffffff;

        svg {
          path {
            fill: #ffffff;
          }
          .first {
            fill: #ffffff;
          }
          .second {
            stroke: #ffffff;
          }
        }
      }
      &:after {
        background: $lagrima;
      }

      svg {
        path {
          fill: $lagrima;
        }
        .first {
          fill: $lagrima;
        }
        .second {
          stroke: $lagrima;
        }
      }
    }

    svg {
      position: absolute;
      top: 22%;
      left: 20px;
      width: auto;
      height: 50%;
    }

    &.desktop-btn {
      @include max-screen($small) {
        display: none;
      }
    }

    &.tablet-btn {
      display: none;
      @include screen($small, 1023px) {
        // --- IPAD Pro ---
        display: inline-block;
      }
    }

    &.mobile-btn {
      display: none;
      @include max-screen($small) {
        display: inline-block;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &.hide-btn {
      display: none;
    }

    &:hover {
      color: #ffffff;

      &:after {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
        transform-origin: bottom left;
        -webkit-transform-origin: bottom left;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: #020202;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform: scaleY(0);
      -webkit-transform: scaleY(0);
      transform-origin: top left;
      -webkit-transform-origin: top left;
      transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  .page-header {
    position: relative;
  }
  .off-box {
    display: none;
    position: absolute;
    bottom: 50px;
    left: 0;
    max-width: 350px;
    width: 100%;
    padding: 5px;
    background-color: #fff3dc;
    z-index: 9999;

    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);

    h2 {
      font-size: 50px;
      line-height: 45px;
    }

    .feedback-container {
      margin: 40px 0;
    }

    form {
      max-width: 100%;
      margin-bottom: 0;
    }

    .close-box {
      position: absolute;
      top: 23px;
      right: 15px;
      width: 35px;
      height: 15px;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;

        transform: rotate(40deg);
        -webkit-transform: rotate(40deg);

        transition: transform 0.25s ease-in-out;
        -webkit-transition: -webkit-transform 0.25s ease-in-out;

        transform-origin: center center;
        -webkit-transform-origin: center center;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;

        transform: rotate(-40deg);
        -webkit-transform: rotate(-40deg);

        transition: transform 0.25s ease-in-out;
        -webkit-transition: -webkit-transform 0.25s ease-in-out;

        transform-origin: center center;
        -webkit-transform-origin: center center;
      }

      &:hover {
        &:before {
          transform: rotate(30deg);
          -webkit-transform: rotate(30deg);
        }
        &:after {
          transform: rotate(-30deg);
          -webkit-transform: rotate(-30deg);
        }
      }
    }
  }
  .mobile-bottle-info {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background: rgba($white, 0.97);
    z-index: 100;

    opacity: 0;
    visibility: hidden;

    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    h2,
    ul,
    p {
      position: relative;
      z-index: 1;
    }
    h2,
    ul {
      text-align: center;
    }
    h2 {
      font-family: "velhotes-roman";
      font-size: 45px;
      letter-spacing: -2.5px;

      margin: 40px 0 60px 0;
      padding: 0 105px;
    }
    ul {
      margin-bottom: 40px;
      li {
        font-family: "velhotes-regular";
        font-size: 12px;
        letter-spacing: 3px;
        text-transform: uppercase;
        opacity: 0.3;
        display: inline;

        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        &.active {
          opacity: 1;
        }
      }
    }
    .mobile-info-container {
      position: relative;
      .pos-center {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      .description-mobile-container {
        p {
          font-family: "velhotes-regular";
          font-size: 21px;
          padding: 0 34px;
          &:last-of-type {
            margin-top: 20px;
          }
        }
      }
      .suggestion-mobile-container {
        opacity: 0;
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
        p {
          font-family: "velhotes-italic";
          font-size: 23px;
          padding: 0 34px;
          &:last-of-type {
            margin-top: 20px;
          }
        }
      }
      .technical-mobile-container {
        opacity: 0;
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
        ul {
          padding: 0 34px;
        }
        ul:not(.info-buttons) {
          li {
            margin-bottom: 5px;
            text-transform: capitalize;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        h2:not(.title) {
          margin: 0;
          padding: 0;
        }
        h2:not(.title),
        h3 {
          font-family: "velhotes-regular";
          font-size: 20px;
          letter-spacing: 0;
          text-align: left;
        }
        h3 {
          color: #261919;
        }
      }
    }
    #close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10000;

      width: 45px;
      height: 45px;
      border-radius: 50%;
      &[data-color="tawny"] {
        background-color: $tawny;
      }
      &[data-color="special"] {
        background-color: $special;
      }
      &[data-color="tenYears"] {
        background-color: $tenYears;
      }
      &[data-color="ruby"] {
        background-color: $ruby;
      }
      &[data-color="lbv"] {
        background-color: $lbv;
      }
      &[data-color="whiteWine"] {
        background-color: $whiteWine;
      }
      &[data-color="lagrima"] {
        background-color: $lagrima;
      }
      span {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;

        width: 25px;
        height: 1px;
        background-color: $white;
        &:first-of-type {
          transform: translateY(-50%) rotate(-45deg);
          -webkit-transform: translateY(-50%) rotate(-45deg);
        }
        &:last-of-type {
          transform: translateY(-50%) rotate(45deg);
          -webkit-transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
  #header-main {
    .header-global {
      #create-meme-btn {
        h2 {
          color: $white;
        }
        #smile-container {
          #wink-container {
            span {
              background: $white;
            }
          }
        }
      }

      .offmenu-btn {
        .text-wrapper {
          color: $white;
        }
        .burguer-wrapper {
          .burguer {
            background-color: $white;
          }
        }
        &.go-dark {
          .burguer-wrapper {
            .burguer {
              background-color: $black;
            }
          }
          .text-wrapper {
            color: $black;
          }
        }
      }
    }
  }
}

// Page Level
.home-page {
  .legacy {
    margin-bottom: 100px;
    img {
      margin: 0 auto;
    }
    .legado-link {
      margin-top: -23px;
      position: absolute;
      width: 100%;
      top: 50%;
      h2 {
        font-family: "velhotes-roman";
        font-size: 110px;
        letter-spacing: -5px;
        text-shadow: -1px 2px 6px rgba(0, 0, 0, 0.3);
      }
      a {
        color: #fff;
        span {
          text-transform: uppercase;
          padding: 30px 10px 8px 10px;
          text-shadow: -1px 2px 6px rgba(0, 0, 0, 0.3);
          display: inline-block;
          font-size: 15px;
          font-family: "velhotes-bold-reg";
          letter-spacing: 3px;
          border-bottom: 2px solid #fff;
        }
      }
    }

    .columns {
      position: relative;
    }
  }
  .underline {
    position: relative;
    &:after {
      content: " ";
      height: 3px;
      width: 100%;

      position: absolute;
      bottom: 10px;
      left: 0;
      display: block;
    }
    &[data-color="tawny"] {
      &:after {
        background: $tawny;
      }
    }
    &[data-color="tenYears"] {
      &:after {
        background: $tenYears;
      }
    }
    &[data-color="special"] {
      &:after {
        background: $special;
      }
    }
    &[data-color="ruby"] {
      &:after {
        background: $ruby;
      }
    }
    &[data-color="lbv"] {
      &:after {
        background: $lbv;
      }
    }
    &[data-color="whiteWine"] {
      &:after {
        background: $whiteWine;
      }
    }
    &[data-color="lagrima"] {
      &:after {
        background: $lagrima;
      }
    }
  }

  .page-header {
    height: 100vh;
  }

  .slogan-wrapper {
    position: relative;
    z-index: 1002;
  }

  .content-main-wrapper {
    #bottle-mobile {
      // display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;

      margin: 0 auto;

      pointer-events: none;

      transform: translateY(-47.5%) scale(10);
      -webkit-transform: translateY(-47.5%) scale(10);

      img {
        position: relative;
        margin: 0 auto;
        height: 100vh;
        opacity: 1;
      }
    }

    #slogan {
      transform: scale(1.3);
      -webkit-transform: scale(1.3);

      opacity: 0;
      margin: -80vh auto 0 auto;
      max-width: 900px;

      font-family: "velhotes-title";
      font-size: 85px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      transition: all 2.5s ease(out-expo);
      -webkit-transition: all 2.5s ease(out-expo);
      &.change-spacing {
        letter-spacing: -1px;
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }

    #bottles-container {
      position: relative;
      // height: 100vh;
      // overflow: hidden;

      .bottles-wrapper {
        position: relative;
        height: 100%;
      }

      #watermark {
        position: fixed;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        z-index: -1000;

        opacity: 0;
        visibility: hidden;

        span {
          font-family: "velhotes-roman";
          font-size: 250px;
          color: #f4f3f3;
          opacity: 0.6;
          line-height: 200px;
          letter-spacing: -6px;
        }
        .date {
          font-size: 310px;
        }
      }

      .bottles-list-container {
        position: fixed;
        top: 0;
        // right: -40px;

        right: 0px;
        left: auto;
        overflow: hidden;
        // transform: translateX(40px);

        z-index: 10;

        width: 150px;
        height: 100vh;
        .bottles-list {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);

          li {
            width: 130px;
            margin-top: 30px;

            cursor: pointer;

            &:first-of-type {
              margin-top: 0;
            }
            transform: translateX(135%);
            -webkit-transform: translateX(135%);
          }
        }
      }
      .bottle-slider {
        position: relative;
        height: 100vh;
        padding-top: 5vh;

        &:first-of-type {
          .bottle-photo {
            img {
              opacity: 0;
            }
          }
        }
        .bottle-parallax {
          position: relative;
          overflow: hidden;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: red;

            z-index: 2;

            -webkit-transition: transform 1s ease(out-expo);
            transition: transform 1s ease(out-expo);
          }

          &.delay-0 {
            &:after {
              -webkit-transition-delay: 0.5s;
              transition-delay: 0.5s;
            }
          }
          &.delay-1 {
            &:after {
              -webkit-transition-delay: 1s;
              transition-delay: 1s;
            }
          }
          &.delay-2 {
            &:after {
              -webkit-transition-delay: 1.2s;
              transition-delay: 1.2s;
            }
          }
          &.delay-3 {
            &:after {
              -webkit-transition-delay: 0.8s;
              transition-delay: 0.8s;
            }
          }

          &.delay-second-0 {
            &:after {
              -webkit-transition-delay: 0.7s;
              transition-delay: 0.7s;
            }
          }
          &.delay-second-1 {
            &:after {
              -webkit-transition-delay: 0.8s;
              transition-delay: 0.8s;
            }
          }
          &.delay-second-2 {
            &:after {
              -webkit-transition-delay: 0.9s;
              transition-delay: 0.9s;
            }
          }
          &.delay-second-3 {
            &:after {
              -webkit-transition-delay: 1s;
              transition-delay: 1s;
            }
          }

          &.unmask {
            &:after {
              -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
            }
          }
        }

        &[data-color="tawny"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $tawny;
            }
          }
        }
        &[data-color="tenYears"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $tenYears;
            }
          }
        }
        &[data-color="special"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $special;
            }
          }
        }
        &[data-color="ruby"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $ruby;
            }
          }
        }
        &[data-color="lbv"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $lbv;
            }
          }
        }
        &[data-color="whiteWine"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $whiteWine;
            }
          }
        }
        &[data-color="lagrima"] {
          .bottle-parallax,
          .bottle-parallax-second {
            &:after {
              background-color: $lagrima;
            }
          }
        }

        .bottle-title {
          font-family: "velhotes-roman";
          font-size: 90px;
          letter-spacing: -5px;
          margin-bottom: 48px;
          margin-left: 54px;
          white-space: nowrap;
          display: inline-block;
          padding: 0 5px 5px 0;

          @include screen($small, 1023px) {
            // --- IPAD Pro ---
            display: block;
            margin-bottom: 0;
          }
        }
        .bottle-info {
          font-family: "velhotes-regular";
          font-size: 21px;
          line-height: 21px;
          color: $black;
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .bottle-photo {
          pointer-events: none;

          img {
            height: 90vh;
            margin: 0 auto;
          }
        }
        &:last-of-type {
          padding-bottom: 5vh;
        }
        .buttons-info-container {
          width: 100%;
          height: 51px;
          //padding-top: 51px;
          margin-bottom: 21px;

          ul:not(.technical-info) {
            position: relative;

            li {
              position: relative;
              display: inline-block;
              padding: 10px 0;
              top: 0;
              left: 0;
              opacity: 1;
              cursor: pointer;

              -webkit-transition: 1.6s all ease(out-expo);
              transition: 1.6s all ease(out-expo);

              &:first-of-type {
                padding-left: 40px;
              }
              &:last-of-type {
                padding-left: 40px;
                margin-left: 20px;
              }
              span {
                font-family: "velhotes-regular";
                font-size: 14px;
                letter-spacing: 4px;
                text-transform: uppercase;
                display: block;
                transition: 1.6s all ease(out-expo);
                -webkit-transition: 1.6s all ease(out-expo);
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0px;

                width: 25px;
                height: 1px;

                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);

                opacity: 1;
                -webkit-transition: 0.6s left ease(out-expo);
                transition: 0.6s left ease(out-expo);
              }
              &.active {
                opacity: 1;
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
        .other-info-container {
          position: relative;
          .suggestion-info {
            font-family: "velhotes-italic";
            font-size: 24px;
            line-height: 28px;
            color: $black;
            margin-bottom: 20px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .quotation-word {
              position: relative;
              &:after {
                content: "”";

                position: absolute;
                top: 30px;
                right: -40px;

                font-family: "velhotes-roman";
                font-size: 90px;
                color: rgba(#85754d, 0.2);
              }
            }
          }
          .technical-info {
            position: absolute;
            top: 0;
            width: 100%;
            opacity: 0;
            transform: translateY(20px);
            -webkit-transform: translateY(20px);
            li {
              margin-bottom: 5px;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
            h2,
            h3 {
              font-family: "velhotes-regular";
              font-size: 21px;
            }
            h3 {
              color: #261919;
            }
          }
        }
        .tablet-bottle-info {
          position: absolute;
          width: 100%;
          top: 390px;
          display: none;
          h2,
          ul,
          p {
            position: relative;
            z-index: 1;
          }
          h2 {
            font-family: "velhotes-roman";
            font-size: 45px;
            letter-spacing: -2.5px;
          }
          ul {
            margin-bottom: 50px;
            li {
              font-family: "velhotes-regular";
              font-size: 12px;
              letter-spacing: 3px;
              text-transform: uppercase;
              opacity: 0.3;
              display: inline;

              margin-right: 20px;
              &:last-of-type {
                margin-right: 0;
              }
              &.active {
                opacity: 1;
              }
            }
          }
          .tablet-info-container {
            position: relative;
            .pos-center {
              position: absolute;
              top: 0;
              left: 0;
            }
            .description-tablet-container {
              p {
                font-family: "velhotes-regular";
                font-size: 21px;
                &:last-of-type {
                  margin-top: 20px;
                }
              }
            }
            .suggestion-tablet-container {
              opacity: 0;
              transform: translateY(20px);
              -webkit-transform: translateY(20px);
              p {
                font-family: "velhotes-italic";
                font-size: 23px;
                &:last-of-type {
                  margin-top: 20px;
                }
              }
            }
            .technical-tablet-container {
              opacity: 0;
              transform: translateY(20px);
              -webkit-transform: translateY(20px);
              width: 100%;
              ul:not(.info-buttons) {
                li {
                  margin-bottom: 5px;
                  text-transform: capitalize;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
              h2:not(.title) {
                margin: 0;
                padding: 0;
              }
              h2:not(.title),
              h3 {
                font-family: "velhotes-regular";
                font-size: 20px;
                letter-spacing: 0;
                text-align: left;
              }
              h3 {
                color: #261919;
              }
            }
          }
        }
      }
    }
  }
}

.mobile {
  .home .donations-modal .close {
    display: block;
  }
}

// Media Queries
@include screen($medium, $xxlarge, $orientation: false) {
}

@include screen($xlarge, 2100px, $orientation: false) {
  // --- MacBook Pro 15'(.ie) ---
}

@include min-screen($medium) {
}

@include screen(1024px, 1366px, $orientation: portrait) {
  // --- IPAD Pro ---
}

@include ipad($orientation: landscape) {
}

@include ipad($orientation: portrait) {
}

@include max-screen($xlarge) {
  .home-page {
    .content-main-wrapper {
      #bottles-container {
        .bottle-slider {
          .bottle-title {
            font-size: 80px;
            margin-left: 0;
          }
        }
        .bottle-slider,
        .bottles-list-container {
          //right: -35px;
          .bottles-list {
            li {
              width: 115px;
            }
          }
        }
      }
    }
  }
}

@include max-screen($medium) {
  .ink-mask .bg-layer h3 {
    font-size: 45px;
  }
  .home-page {
    .content-main-wrapper {
      .scroll-helper {
        top: 88vh;
        margin-left: 3px;
      }
      #bottle-mobile {
        display: block;
        top: 10vh;

        -webkit-transform: translate(-20%, 150%) rotate(10deg) scale(1);
        transform: translate(-20%, 150%) rotate(10deg) scale(1);

        img {
          height: 80vh;
          margin: 0 auto;

          opacity: 1;
          visibility: visible;

          -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
        }
      }
      #bottles-container {
        .bottle-slider {
          padding-top: 10vh;
          .bottle-parallax {
            overflow: initial;
            &:after {
              display: none;
            }
          }
          .bottle-title {
            font-size: 65px;
          }
          .bottle-photo {
            img {
              height: 80vh;
            }
          }
          .other-info-container .suggestion-info {
            font-size: 21px;
            line-height: 25px;
          }
          .buttons-info-container {
            ul:not(.technical-info) li {
              width: 160px;
              opacity: 0.3;
              &:before {
                left: 0;
                width: 15px;
              }
              &:last-of-type {
                left: 0px;
                margin-left: 15px;
                padding-left: 15px;
                &:before {
                  left: -25px;
                }
              }

              span {
                font-size: 12px;
              }
            }
          }
        }
        .bottles-list-container {
          display: none;
        }
      }
    }
  }
}

@include max-screen($small) {
  // --- iPad portrait ---
  .ink-mask .bg-layer h3 {
    font-size: 55px;
  }
  .home-page {
    .content-main-wrapper {
      .scroll-helper {
        top: 90vh;
      }
      #bottles-container {
        .bottle-slider {
          height: 80vh;
          .tablet-bottle-info {
            display: block;
          }
          .bottle-info,
          .buttons-info-container,
          .other-info-container {
            display: none;
          }
          .bottle-title {
            font-size: 85px;
          }
          .bottle-photo {
            position: absolute;
            top: 50%;
            right: 30px;

            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            img {
              height: 65vh;
            }
          }
          .other-info-container .suggestion-info {
            font-size: 24px;
            line-height: 29px;
          }
          .buttons-info-container {
            padding: 80px 0 50px 0;
            ul:not(.technical-info) li {
              width: 180px;
              span {
                font-size: 14px;
              }
            }
          }
          &:nth-child(1),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            padding-top: 200px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-top: 150px;
            .tablet-bottle-info {
              top: 430px;
            }
          }
        }
      }
    }
  }
}

@include max-screen($xsmall) {
  // --- iPhone 6/6S/7 and plus versions portrait ---
  .ink-mask .bg-layer h3 {
    font-size: 28px;
    line-height: 35px;
    white-space: normal;
    margin-bottom: 50px;
    margin-top: 50px;
    &:before {
      top: -38px;
    }
  }
  footer {
    h2 {
      font-size: 35px;
      line-height: 35px;
    }
    h3 {
      font-size: 18px;
      padding: 0;
    }
  }
  form {
    input[type="text"],
    input[type="email"] {
      width: 100%;
      padding: 20px 30px;
    }
    button {
      width: 50px;
      svg {
        height: 20.5px;
        right: calc(25px - 17.5px / 2);
      }
    }
  }
  .home {
    .mobile-bottle-info {
      overflow-y: scroll;
      .mobile-info-container {
        .suggestion-mobile-container {
          padding-bottom: 50px;
        }
      }
    }
  }
  .home-page {
    .legacy {
      img {
        max-width: none;
        height: 550px;
        left: -250px;
        position: relative;
      }

      .legado-link {
        left: 0;
        margin-top: -80px;
        h2 {
          font-size: 80px;
        }
      }
    }
    .underline:after {
      bottom: 5px;
    }
    .content-main-wrapper {
      .scroll-helper {
        top: 88vh;
        left: initial;
        right: -30px;
      }
      #bottle-mobile {
        display: block;
        top: 100px;

        -webkit-transform: translate(-20%, 150%) rotate(10deg) scale(1);
        transform: translate(-20%, 150%) rotate(10deg) scale(1);

        img {
          height: 500px;
          width: auto;
          margin: 0 auto;

          opacity: 1;
          visibility: visible;

          -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
        }
      }
      #slogan {
        padding: 0 10px;
        margin-top: -70vh;
        font-size: 40px;
        letter-spacing: -1px;
      }
      #bottles-container {
        padding-top: 80px;
        height: auto;
        width: 100vw;

        #watermark {
          span {
            font-size: 130px;
            line-height: 120px;
          }
          .date {
            font-size: 160px;
          }
        }
        .bottle-slider {
          height: 400px !important;
          margin-bottom: 120px;
          padding-top: 0;
          .tablet-bottle-info {
            display: none;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          .bottle-info,
          ul:not(.technical-info),
          .other-info-container {
            display: none;
          }

          .bottle-photo {
            right: 0;
            left: 0;
            margin: 0 auto;
            img {
              height: 50vh;
            }
          }
          .bottle-title {
            position: absolute;
            bottom: -35px;
            left: 0;
            right: 0;

            margin: 0 auto;
            font-size: 53px;
            line-height: 50px;
            white-space: normal;
            text-align: center;
            padding-right: 8px;
            z-index: 11;
          }
          &:nth-child(2),
          &:nth-child(3) {
            .bottle-title {
              bottom: -85px;
            }
          }
          &:nth-child(1),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            padding-top: 0;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-top: 0;
          }
          &:last-of-type {
            padding-bottom: 0 !important;
          }
          &:first-of-type {
            .bottle-photo img {
              opacity: 1;
              -webkit-transform: none;
              transform: none;
            }
          }
        }
        .bottles-list-container {
          display: none;
        }
      }
    }
  }
}

@include max-screen($xxsmall) {
  // --- iPhone 4/4S/5/5S portrait ---
  .ink-mask .bg-layer h3 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
  }
  .home {
    .mobile-bottle-info {
      overflow-y: scroll;
      h2 {
        font-size: 40px;
      }
      .mobile-info-container {
        .technical-mobile-container {
          ul {
            li {
              opacity: 1;
            }
          }
          ul:not(.info-buttons) li {
            margin-bottom: 15px;
          }
        }
        .pos-center {
          padding-bottom: 50px;
        }
      }
      ul {
        li {
          margin-bottom: 20px;
          margin-right: 0;
          display: block;
        }
      }
    }
  }
  .home-page {
    .content-main-wrapper {
      #slogan {
        font-size: 32px;
        letter-spacing: -1px;
      }
      #bottle-mobile {
        img {
          width: auto;
        }
      }
      #bottles-container {
        #watermark {
          span {
            font-size: 115px;
            line-height: 100px;
          }
          .date {
            font-size: 145px;
          }
        }
        .bottle-slider {
          margin-bottom: 20px;
          .bottle-title {
            font-size: 47px;
            line-height: 40px;
            bottom: 35px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            .bottle-title {
              bottom: -5px;
            }
          }
        }
      }
    }
  }
}
