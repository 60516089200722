// Body Level
.cocktails {
  &.js-open-offmenu {
    #header-main .header-global .offmenu-btn .burguer-wrapper .burguer {
      background-color: $black;
    }

    #header-main .header-global .offmenu-btn .text-wrapper .text {
      color: $black;
    }
  }

  // #header-main .header-global .offmenu-btn .burguer-wrapper .burguer {
  //   background-color: #ffffff;
  // }

  // #header-main .header-global .offmenu-btn .text-wrapper .text {
  //   color: #ffffff;
  // }

  .create-meme-link {
    display: none;
  }

  #header-main {
    position: fixed;

    width: 100vw;
    height: auto;
    z-index: 1000;
  }
  .mobile-bottle-info {
    display: none;
  }

  .border {
    &.top,
    &.bottom {
      width: 100vw;
    }
    &.left,
    &.right {
      height: 100vh;
    }
  }

  #header-main {
    .block-bg-cover {
      display: none;
    }

    .velhotes-logo {
      // display: none;
      opacity: 0;
      visibility: hidden;

      .letters {
        display: block;
        &.add-transition {
          -webkit-transition: opacity 1s ease-in-out;
          transition: opacity 1s ease-in-out;
        }
      }
      .logo-front {
        -webkit-transition: fill 1s ease-in-out;
        transition: fill 1s ease-in-out;
      }
      svg {
        opacity: 1;
        visibility: visible;
      }

      &.can-anime {
        -webkit-transition: -webkit-transform 0.25s ease-in-out;
        transition: transform 0.25s ease-in-out;
      }
    }

    .header-global {
      -webkit-transition: background 0.25s ease-in-out,
        box-shadow 0.25s ease-in-out, padding 0.25s ease-in-out;
      transition: background 0.25s ease-in-out,
        -webkit-box-shadow 0.25s ease-in-out, padding 0.25s ease-in-out;

      #create-meme-btn {
        #smile-container {
          svg {
            path {
              stroke: #000;
            }
          }
          #eye {
            border: 2px solid $black;
          }
        }

        h2,
        .char {
          opacity: 1;
          visibility: visible;
        }
      }

      #cria-o-meme {
        display: none;
      }

      &.active {
        background: rgba($white, 0.97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
        padding: 23px 53px;

        .velhotes-logo {
          transform: scale(0.85);
          -webkit-transform: scale(0.85);
        }
      }
    }

    &.active {
      .velhotes-logo {
        .logo-front {
          fill: #ffffff;
        }
        .letters {
          opacity: 0;
        }
      }
    }
  }
}

.cocktails-page {
  .page-header {
    position: relative;
    width: 100vw;
    height: auto;
    z-index: 1;

    .image-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .bg {
        @include max-screen($small) {
          max-width: 190%;
          display: none;
        }

        @include max-screen($xsmall) {
          max-width: 230%;
        }
      }

      .velhotes {
        position: absolute;
        max-width: 45%;
        left: 10%;

        @include max-screen($small) {
          display: none;
        }
      }

      .logo {
        position: absolute;
        max-width: 29%;
        right: 14%;

        @include max-screen($small) {
          display: none;
        }

        // @include max-screen($large) {
        //   max-width: 430px;
        // }

        // @include max-screen(1280px) {
        //   max-width: 380px;
        // }

        // @include max-screen($medium) {
        //   max-width: 300px;
        // }

        // @include max-screen($small) {
        //   max-width: 360px;
        // }

        // @include max-screen($xsmall) {
        //   max-width: 70%;
        // }
      }

      .header-mobile {
        display: none;

        @include max-screen($small) {
          display: block;
        }
      }
    }
  }

  .content-main-wrapper {
    position: relative;

    z-index: 2;

    .garrafas-section {
      padding-top: 130px;
      padding-bottom: 150px;

      @include max-screen($xsmall) {
        padding-top: 100px;
        padding-bottom: 120px;
      }

      @include max-screen($xxsmall) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      h1 {
        font-family: "velhotes-heavy";
        font-style: normal;
        font-weight: 800;
        font-size: 85px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #7b222e;
        font-feature-settings: "salt" on;

        @include max-screen($large) {
          font-size: 80px;
        }

        @include max-screen(1280px) {
          font-size: 70px;
        }

        @include max-screen($medium) {
          font-size: 60px;
        }

        @include max-screen($xsmall) {
          font-size: 44px;
        }

        @include max-screen($xxsmall) {
          font-size: 34px;
        }
      }

      p {
        font-family: "velhotes-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        max-width: 650px;
        width: 100%;
        margin: 32px auto 0;

        @include max-screen(1280px) {
          font-size: 21px;
          max-width: 700px;
        }

        @include max-screen($xxsmall) {
          font-size: 16px;
        }
      }

      .bottle-row {
        margin-top: 144px;

        @include max-screen($small) {
          margin-top: 120px;
        }

        @include max-screen($xsmall) {
          .columns {
            margin-bottom: 100px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        @include max-screen($xxsmall) {
          margin-top: 80px;

          .columns {
            margin-bottom: 80px;
          }
        }

        h2 {
          font-family: "velhotes-bold-name";
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 105.5%;
          text-align: center;
          letter-spacing: -0.03em;
          color: rgba(#000000, 0.7);
          font-feature-settings: "salt" on;
          margin-top: 20px;

          @include max-screen(1280px) {
            font-size: 28px;
          }

          @include max-screen($xsmall) {
            br {
              display: none;
            }
          }

          @include max-screen($xxsmall) {
            font-size: 24px;
          }
        }

        p {
          font-family: "velhotes-bold-name";
          font-style: normal;
          font-weight: normal;
          font-size: 30px;
          line-height: 105.5%;
          text-align: center;
          letter-spacing: -0.03em;
          margin-top: 20px;

          @include max-screen(1280px) {
            font-size: 24px;
          }

          @include max-screen($xxsmall) {
            font-size: 20px;
            margin-top: 10px;
          }
        }

        .bottle-left {
          max-width: 240px;
          width: 100%;
          margin: 0 auto;

          @include max-screen($large) {
            max-width: 200px;
          }

          @include max-screen($medium) {
            max-width: 190px;
          }

          @include max-screen($small) {
            max-width: 160px;
          }

          @include max-screen($xxsmall) {
            max-width: 50%;
          }
        }

        .bottle-mid {
          max-width: 400px;
          width: 100%;
          margin: 0 auto;

          @include max-screen($large) {
            max-width: 329px;
          }

          @include max-screen($medium) {
            max-width: 313px;
          }

          @include max-screen($small) {
            max-width: 260px;
          }

          @include max-screen($xxsmall) {
            max-width: 80%;
          }
        }

        .bottle-right {
          max-width: 144px;
          width: 100%;
          margin: 0 auto;

          @include max-screen($large) {
            max-width: 118px;
          }

          @include max-screen($medium) {
            max-width: 111px;
          }

          @include max-screen($small) {
            max-width: 93px;
          }

          @include max-screen($xxsmall) {
            max-width: 30%;
          }
        }
      }
    }

    .cocktail-section {
      position: relative;
      background-color: #fff8e9;
      padding: 170px 0;

      @include max-screen($medium) {
        padding: 120px 0;
      }

      @include max-screen($xxsmall) {
        padding: 80px 0;
      }

      h2 {
        font-family: "velhotes-heavy";
        font-style: normal;
        font-weight: 800;
        font-size: 85px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #000000;
        font-feature-settings: "salt" on;

        @include max-screen($large) {
          font-size: 80px;
        }

        @include max-screen(1280px) {
          font-size: 70px;
        }

        @include max-screen($medium) {
          font-size: 60px;
        }

        @include max-screen($xsmall) {
          font-size: 44px;
        }

        @include max-screen($xxsmall) {
          font-size: 34px;
        }
      }

      p {
        font-family: "velhotes-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        max-width: 650px;
        width: 100%;
        margin: 32px auto 0;

        @include max-screen(1280px) {
          font-size: 21px;
          max-width: 700px;
        }

        @include max-screen($xxsmall) {
          font-size: 16px;
        }
      }

      .cocktails-list {
        margin-top: 250px;

        @include max-screen($medium) {
          margin-top: 150px;
        }

        @include max-screen($small) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include max-screen($xsmall) {
          margin-top: 100px;
        }

        @include max-screen($xxsmall) {
          margin-top: 80px;
        }

        .list {
          position: relative;
          padding: 70px 0;
          margin-bottom: 150px;

          @include max-screen($medium) {
            margin-bottom: 120px;
            padding: 50px 0;
          }

          @include max-screen($xsmall) {
            display: flex;
            flex-direction: column;
            padding: 30px;
            padding-bottom: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h3 {
          font-family: "velhotes-heavy";
          font-style: normal;
          font-weight: 800;
          font-size: 59px;
          line-height: 100%;
          letter-spacing: -0.03em;
          color: rgba(#000000, 0.7);
          font-feature-settings: "salt" on;
          margin-bottom: 35px;
          text-transform: uppercase;

          @include max-screen($large) {
            font-size: 54px;
          }

          @include max-screen($medium) {
            font-size: 50px;
          }

          @include max-screen($xsmall) {
            font-size: 40px;
          }

          @include max-screen($xxsmall) {
            font-size: 30px;
          }
        }

        .specs-list {
          font-family: "velhotes-bold-name";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: -0.01em;
          color: rgba(#000000, 0.6);

          @include max-screen($xxsmall) {
            font-size: 18px;
          }

          li {
            @include max-screen($small) {
              margin-bottom: 20px;
            }
          }
        }

        .porto-tonico {
          background-color: #f5e7b4;

          img {
            position: absolute;
            bottom: 0;
            left: -5%;
            max-width: 27%;
            width: 100%;

            @include max-screen($small) {
              max-width: 33%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              left: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 18%;
            }
          }
        }

        .orange-tawny {
          background-color: #e5aea8;

          img {
            position: absolute;
            bottom: 0;
            right: 2%;
            max-width: 22%;
            width: 100%;

            @include max-screen($small) {
              max-width: 28%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              right: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 14%;
            }
          }
        }

        .ruby-cherry {
          background-color: #fccdd0;

          img {
            position: absolute;
            bottom: 0;
            left: 2%;
            max-width: 19%;
            width: 100%;

            @include max-screen($small) {
              max-width: 25%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              left: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 12%;
            }
          }
        }
        .white-pepino {
          background-color: #fbefc4;

          img {
            position: absolute;
            bottom: 0;
            right: 2%;
            max-width: 18.5%;
            width: 100%;

            @include max-screen($small) {
              max-width: 24%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              right: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 12%;
            }
          }
        }

        .ruby-strawberry {
          background-color: #fccdd0;

          img {
            position: absolute;
            bottom: 0;
            left: 2%;
            max-width: 20%;
            width: 100%;

            @include max-screen($small) {
              max-width: 26%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              left: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 13%;
            }
          }
        }
        .ruby-coffee {
          background-color: #f1bfbf;

          img {
            position: absolute;
            bottom: -5%;
            right: -2%;
            max-width: 25%;
            width: 100%;

            @include max-screen($small) {
              max-width: 31%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              right: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 15%;
            }
          }
        }
        .orange-lbv {
          background-color: #f4c2a5;

          img {
            position: absolute;
            bottom: -5%;
            left: 0;
            max-width: 22%;
            width: 100%;

            @include max-screen($small) {
              max-width: 28%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              left: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 14%;
            }
          }
        }
        .gengibre-toranja {
          background-color: #ffafa0;

          img {
            position: absolute;
            bottom: 0;
            right: 2%;
            max-width: 21%;
            width: 100%;

            @include max-screen($small) {
              max-width: 27%;
            }

            @include max-screen($xsmall) {
              position: relative;
              order: 2;
              right: auto;
              bottom: auto;
              max-width: 70%;
              margin: 0 auto;
              margin-top: 30px;
            }

            @include min-screen($xxlarge) {
              max-width: 14%;
            }
          }
        }
      }
    }

    .store-section {
      padding: 130px 0;

      @include max-screen($xsmall) {
        padding: 100px 0;
      }

      @include max-screen($xxsmall) {
        padding: 80px 0;
      }

      h2 {
        font-family: "velhotes-heavy";
        font-style: normal;
        font-weight: 800;
        font-size: 85px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #000000;
        font-feature-settings: "salt" on;

        @include max-screen($large) {
          font-size: 80px;
        }

        @include max-screen(1280px) {
          font-size: 70px;
        }

        @include max-screen($medium) {
          font-size: 60px;
        }

        @include max-screen($xsmall) {
          font-size: 44px;
        }

        @include max-screen($xxsmall) {
          font-size: 34px;
        }
      }

      .columns {
        text-align: center;
      }

      .box {
        max-width: 520px;
        width: 100%;
        margin: 30px auto 0;

        @include max-screen(1280px) {
          max-width: 480px;
        }

        @include max-screen($medium) {
          max-width: 400px;
        }
      }

      .buy-now-btn {
        display: inline-block;
        position: relative;

        margin: 40px 0;
        padding: 15px 40px 15px 60px;
        pointer-events: all;
        font-family: "velhotes-bold";
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #7e3240;
        border: 2px solid #7e3240;

        transition: 0.4s color cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transition: 0.4s color cubic-bezier(0.19, 1, 0.22, 1);
        overflow: hidden;

        span {
          position: relative;
          left: 10px;
        }

        svg {
          position: absolute;
          top: 22%;
          left: 20px;
          width: auto;
          height: 50%;

          path {
            fill: #7e3240;
          }
          .first {
            fill: #7e3240;
          }
          .second {
            stroke: #7e3240;
          }
        }

        &:hover {
          color: #ffffff;

          svg {
            path {
              fill: #ffffff;
            }
            .first {
              fill: #ffffff;
            }
            .second {
              stroke: #ffffff;
            }
          }

          &:after {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
            transform-origin: bottom left;
            -webkit-transform-origin: bottom left;
          }
        }

        &:after {
          content: "";
          width: 105%;
          height: 100%;
          background: #7e3240;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform: scaleY(0);
          -webkit-transform: scaleY(0);
          transform-origin: top left;
          -webkit-transform-origin: top left;
          transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
  }
}
