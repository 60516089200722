// Body Level
.covid {
  &.js-open-offmenu {
    #header-main .header-global .offmenu-btn .burguer-wrapper .burguer {
      background-color: $black;
    }

    #header-main .header-global .offmenu-btn .text-wrapper .text {
      color: $black;
    }
  }

  #header-main .header-global .offmenu-btn .burguer-wrapper .burguer {
    background-color: #ffffff;
  }

  #header-main .header-global .offmenu-btn .text-wrapper .text {
    color: #ffffff;
  }

  #header-main {
    position: fixed;

    width: 100vw;
    height: auto;
    z-index: 1000;
  }
  .mobile-bottle-info {
    display: none;
  }

  .border {
    &.top,
    &.bottom {
      width: 100vw;
    }
    &.left,
    &.right {
      height: 100vh;
    }
  }
  #header-main {
    .block-bg-cover {
      display: none;
    }

    .velhotes-logo {
      opacity: 1;
      visibility: visible;

      .letters {
        display: block;
        &.add-transition {
          -webkit-transition: opacity 1s ease-in-out;
          transition: opacity 1s ease-in-out;
        }
      }
      .logo-front {
        -webkit-transition: fill 1s ease-in-out;
        transition: fill 1s ease-in-out;
      }
      svg {
        opacity: 1;
        visibility: visible;
      }

      &.can-anime {
        -webkit-transition: -webkit-transform 0.25s ease-in-out;
        transition: transform 0.25s ease-in-out;
      }
    }

    .header-global {
      -webkit-transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out, padding 0.25s ease-in-out;
      transition: background 0.25s ease-in-out, -webkit-box-shadow 0.25s ease-in-out, padding 0.25s ease-in-out;

      #create-meme-btn {
        #smile-container {
          svg {
            path {
              stroke: #000;
            }
          }
          #eye {
            border: 2px solid $black;
          }
        }

        h2,
        .char {
          opacity: 1;
          visibility: visible;
        }
      }

      #cria-o-meme {
        display: none;
      }

      &.active {
        background: rgba($white, 0.97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
        padding: 23px 53px;

        .velhotes-logo {
          transform: scale(0.85);
          -webkit-transform: scale(0.85);
        }
      }
    }

    &.active {
      .velhotes-logo {
        .logo-front {
          fill: #ffffff;
        }
        .letters {
          opacity: 0;
        }
      }
    }
  }
}

.covid-page {
  .page-header {
    position: relative;
    height: 100vh;
    z-index: 1;

    .block-bg-cover {
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
        z-index: 1;
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .row {
      position: relative;
      height: 100%;
      text-align: center;

      img {
        margin: 0 auto;

        @include max-screen($xsmall) {
          &.header-title {
            position: relative;
            width: 120%;
            max-width: 120%;
            left: -10%;
          }
        }
      }

      h1 {
        font-family: "velhotes-heavy";
        font-style: normal;
        font-weight: 800;
        font-size: 90px;
        line-height: 100%;
        /* or 90px */

        text-align: center;
        letter-spacing: -0.03em;
        text-transform: uppercase;
      }

      .btns-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        max-width: 600px;
        width: 100%;

        margin: 0 auto;

        @include max-screen($xsmall) {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding: 0 50px;
          margin-top: 40px;
        }
      }

      .covid-button {
        margin: 40px 10px 0 10px;

        border: 1px solid transparent;

        @include max-screen($small) {
          margin: 80px auto 0 auto;
        }

        @include max-screen($xsmall) {
          margin: 0;
          margin-bottom: 20px;
        }
      }
      .play-btn {
        border: 1px solid #ffffff;
        background-color: transparent;

        span {
          color: #ffffff;

          @include max-screen($xsmall) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .covid-button {
    position: relative;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;

    padding: 20px 25px;

    font-family: "velhotes-roman";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.13em;
    text-transform: uppercase;

    background: #000000;

    transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;

    &:hover {
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
    }

    span {
      position: relative;
      color: #ffffff;
      margin-right: 40px;
    }
  }

  .content-main-wrapper {
    position: relative;

    margin-top: 100px;
    margin-bottom: 100px;
    z-index: 2;

    strong {
      font-family: "velhotes-bold";
    }

    .paypal-form {
      display: none;
    }

    .left-col {
      h2 {
        margin-bottom: 30px;

        font-family: "velhotes-roman";
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 102.5%;
        letter-spacing: -0.03em;

        color: #000000;

        @include max-screen($small) {
          font-size: 42px;
        }
      }
      h3 {
        margin-top: 40px;
        margin-bottom: 40px;

        font-family: "velhotes-bold";
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 119.3%;
        letter-spacing: -0.04em;

        color: #9d9154;

        @include max-screen($small) {
          font-size: 38px;
        }
        @include max-screen($xsmall) {
          line-height: 1.1em;
        }
      }
      p {
        margin-bottom: 20px;

        font-family: "velhotes-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 1.2em;
        letter-spacing: -0.02em;

        color: #000000;
      }

      img {
        padding: 0 40px 0 20px;
        margin-bottom: 20px;
      }

      .list-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;

        p {
          font-family: "velhotes-heavy";
          font-style: normal;
          text-transform: uppercase;
          margin-bottom: 10px;
          font-size: 22px;

          @include max-screen($xsmall) {
            font-size: 14px;
          }
        }
        .subtitle {
          font-family: "velhotes-heavy";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 1.2em;
          letter-spacing: -0.02em;

          color: #aaa;
          text-transform: uppercase;
        }
      }

      ul {
        margin-top: 20px;
        margin-bottom: 40px;
      }
      li {
        margin: 10px 0;
        font-family: "velhotes-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 1.2em;
        letter-spacing: -0.02em;

        color: #000;

        @include max-screen($xsmall) {
          font-size: 18px;
        }
      }
    }

    .donation-form {
      @include max-screen($small) {
        max-width: 100%;
      }
    }

    .donation-form-col {
      padding-left: 40px;

      @include max-screen($small) {
        padding-left: 0px;
      }
    }
    .right-col {
      position: relative;
      margin-top: -200px;

      // @include max-screen($small) {
      //   margin-top: 80px;
      // }

      h2 {
        margin-bottom: 10px;

        font-family: "velhotes-heavy";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 105.5%;

        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: #000000;

        @include max-screen($small) {
          font-size: 26px;
        }

        @include max-screen($xsmall) {
          letter-spacing: -0.05em;
        }
      }

      p {
        font-family: "velhotes-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #a7a3a3;
      }

      .donation-header {
        position: relative;
        padding: 55px;
        background-color: #ffffff;

        box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.05);

        @include max-screen($xsmall) {
          padding: 30px;
        }

        h2 {
          span {
            color: #ed1b24;
          }
        }

        .donation-totals {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          justify-content: space-between;

          margin-top: 70px;

          @include max-screen($xsmall) {
            margin-top: 30px;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: -12px;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #e3d3ae;
          }

          .value {
            display: block;
            font-family: "velhotes-heavy";
            font-style: normal;
            font-weight: 800;
            font-size: 45px;
            text-transform: uppercase;
            color: #000000;

            @include max-screen($small) {
              font-size: 38px;
            }

            span {
              position: relative;
              margin-right: 5px;

              font-family: "velhotes-regular";
              font-size: 25px;
            }
          }
          .number {
            display: block;
            font-family: "velhotes-regular";
            font-style: normal;
            font-size: 20px;
            letter-spacing: -0.02em;
            color: #000000;

            span {
              font-family: "velhotes-heavy";
            }
          }
        }
      }

      .donation-form-wrapper {
        position: relative;
        padding: 55px;
        margin-top: 70px;
        margin-bottom: 130px;

        border: 2px solid #e5dfd0;

        @include max-screen($xsmall) {
          padding: 30px;
        }

        @include max-screen($xxsmall) {
          padding: 10px;
        }

        .form-header {
          position: relative;

          .phase-2-header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            opacity: 0;
            visibility: hidden;

            .thank-you-phrases {
              position: relative;

              .mbway-message {
                position: relative;
                opacity: 0;
                visibility: hidden;
              }

              .paypal-message,
              .iban-message {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }

        p {
          position: relative;
          margin-bottom: 50px;

          &:after {
            content: "";
            position: absolute;
            bottom: -22px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(#000000, 0.1);
          }
        }

        .donation-type {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: flex-end;
          -ms-flex-align: flex-end;
          align-items: flex-end;
          -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
          justify-content: space-evenly;

          margin-bottom: 32px;

          .paypal-wrapper {
            img {
              position: relative;
              top: 5px;
            }
          }

          .radio-wrapper {
            text-align: center;
            cursor: pointer;

            &.active {
              pointer-events: none;
            }

            img {
              pointer-events: none;
              display: block;
              position: relative;
              margin-bottom: 10px;
            }

            .radio-element {
              position: relative;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              .radio-inner {
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: rgba(#000, 0.1);
                border-radius: 100%;
                pointer-events: none;
              }

              .radio {
                width: 26px;
                height: 26px;
                background-color: #ffffff;
                border-radius: 100%;
                padding: 0;
                cursor: pointer;
                -webkit-appearance: none;
                outline: none;
                border: 0;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09);
                -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.09);

                &:checked + .radio-inner {
                  background-color: rgba(#000, 1);
                }
              }
            }
          }
        }

        .phase-wrapper {
          position: relative;
        }

        .phase-1 {
          position: relative;

          display: none;

          .recipte {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 38px;

            label {
              cursor: pointer;
            }

            .recipte-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;

              input {
                width: 14px;
                height: 14px;
                background: #ffffff;
                border-radius: 0px;
                border: 1px solid #000000;
                line-height: 1;
                padding: 0;
                -webkit-appearance: none;
                cursor: pointer;

                &:checked + .svg-check {
                  opacity: 1;
                }
              }

              .svg-check {
                position: absolute;
                pointer-events: none;
                opacity: 0;
              }
            }

            p {
              margin: 0 0 0 10px;

              font-family: "velhotes-regular";
              font-style: normal;
              font-weight: normal;
              font-size: 17px;
              line-height: 105.5%;
              letter-spacing: 0.01em;

              color: #000000;

              &:after {
                display: none;
              }
            }
          }
        }
        .phase-2 {
          position: absolute;
          width: 100%;
          height: 100%;

          top: 0;
          left: 0;

          pointer-events: none;

          .paypal-button,
          .mb-button {
            display: block;
            cursor: pointer;

            transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
            will-change: transform;

            &:hover {
              transform: scale(0.95);
              -webkit-transform: scale(0.95);
            }
          }

          label {
            opacity: 0;
            visibility: hidden;
          }

          .fields {
            position: absolute;
            width: 100%;
            height: 100%;

            top: 0;
            left: 0;

            pointer-events: visible;

            opacity: 0;
            visibility: hidden;

            &.iban-fields {
              img {
                margin: 0 auto;
              }
            }
            &.mbway-fields {
              p {
                margin-top: 10px;
              }
            }
            &.paypal-fields,
            &.mbway-fields {
              img {
                margin: 0 auto;
              }
              p {
                font-size: 18px;
                text-align: center;
                color: #000000;
                opacity: 0.4;

                padding: 0 40px;

                &:after {
                  display: none;
                }
              }
            }
          }
        }

        .form-footer {
          position: relative;
          .phase-1-footer {
            position: relative;

            @include max-screen($xsmall) {
              padding-bottom: 20px;
            }

            .donate {
              width: 100%;
              justify-content: space-between;
            }
          }
          .phase-2-footer {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            opacity: 0;
            visibility: hidden;

            button {
              &.back {
                background-color: transparent;
                padding: 20px 25px;

                @include max-screen($xxsmall) {
                  padding: 10px 15px;
                }

                span {
                  color: #000000;
                  margin: 0;
                }
              }
              &.done {
                background-color: transparent;
                border: 1px solid #000000;

                @include max-screen($xxsmall) {
                  padding: 10px 15px;
                }

                span {
                  color: #000000;
                }
              }
            }
            p {
              position: absolute;
              left: 0;
              top: -70px;

              font-size: 19px;
              color: #000000;
              opacity: 0.6;

              @include max-screen($xsmall) {
                font-size: 14px;
              }

              &:after {
                display: none;
              }
            }
          }
        }

        .input-wrapper:not(.recipte) {
          position: relative;

          margin-bottom: 38px;

          &.nif-input {
            opacity: 0.5;
            pointer-events: none;

            &.active {
              opacity: 1;
              pointer-events: visible;
            }
          }

          &.error {
            .error-message {
              opacity: 1;
              visibility: visible;
            }
          }

          .error-message {
            position: absolute;
            bottom: -20px;
            left: 20px;

            font-family: "velhotes-regular";
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 105.5%;
            letter-spacing: 0.01em;

            color: red;

            opacity: 0;
            visibility: hidden;

            &:after {
              display: none;
            }
          }

          label {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0 20px;

            display: flex;
            align-items: center;

            font-family: "velhotes-bold-reg";

            font-size: 17px;
            line-height: 105.5%;

            letter-spacing: 0.01em;
            text-transform: uppercase;

            color: #b9ad95;
            pointer-events: none;
          }
          input {
            max-width: 100%;
            height: 58px;
            padding: 0 20px;

            font-family: "velhotes-heavy";
            font-style: normal;
            font-weight: 800;
            font-size: 21px;
            line-height: 105.5%;
            letter-spacing: 0.01em;
            text-transform: uppercase;

            color: #000000;

            @include max-screen(1400px) {
              font-size: 18px;
            }
            @include max-screen(1080px) {
              font-size: 16px;
            }

            @include max-screen($xsmall) {
              font-size: 12px;
            }
            @include max-screen($xxsmall) {
              font-size: 10px;
            }

            &#ammount,
            &#ammount-mbway,
            &#ammount-paypal,
            &#phone {
              font-size: 35px;
            }
          }
        }

        .covid-button {
          margin: 0 auto;

          top: initial;
          right: initial;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
