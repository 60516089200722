/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
MAIN SASS CONFIGURATION

* @Version:    1.0 - 2017
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
= INDEX OF CONTENTS
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

// 1. COLORS
// 2. EASINGS
// 3. TYPOGRAPHY
// 4. BASE
// 5. GLOBAL
// 6. MIXINS

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
1. COLORS
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */
$white: #ffffff;
$black: #000000;
$background: #020202;
$brown: #a68676;
$tawny: #8e5a30;
$special: #aa806a;
$tenYears: #bababa;
$ruby: #8a2b3f;
$lbv: #a73c39;
$whiteWine: #679156;
$lagrima: #736641;
$dark-brown: #261919;
$wine: #150e0e;
$cream: #faf7ec;

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
2. EASINGS
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

$ease: (
  in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
  in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
  out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
  out-expo: cubic-bezier(0.19, 1, 0.22, 1),
  out-circ: cubic-bezier(0.075, 0.82, 0.165, 1),
  out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
  in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),
  in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
  in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
  in-out-expo: cubic-bezier(1, 0, 0, 1),
  in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86),
  in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
);

@function ease($key) {
  @if map-has-key($ease, $key) {
    @return map-get($ease, $key);
  }

  @warn "Unkown '#{$key}' in $ease.";
  @return null;
}
/*
.example {
  animation: there-and-back 2.5s ease(in-quad) infinite alternate;
}
*/

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
3. TYPOGRAPHY
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

/*-----------------------------------------------------------------------
TYPOGRAPHY

* @Version:    1.0 - 2017
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
@font-face {
  font-family: "velhotes-title";
  src: url("/public/fonts/velhotes-title.woff2") format("woff2"), url("/public/fonts/velhotes-title.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-roman";
  src: url("/public/fonts/velhotes-roman.woff2") format("woff2"), url("/public/fonts/velhotes-roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-regular";
  src: url("/public/fonts/velhotes-regular.woff2") format("woff2"), url("/public/fonts/velhotes-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-bold";
  src: url("/public/fonts/velhotes-bold.woff2") format("woff2"), url("/public/fonts/velhotes-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-bold-name";
  src: url("/public/fonts/velhotes-bold-name.woff2") format("woff2"), url("/public/fonts/velhotes-bold-name.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-heavy";
  src: url("/public/fonts/velhotes-heavy.woff2") format("woff2"), url("/public/fonts/velhotes-heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-italic";
  src: url("/public/fonts/velhotes-italic.woff2") format("woff2"), url("/public/fonts/velhotes-italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "velhotes-bold-reg";
  src: url("/public/fonts/velhotes-Bold-Reg.woff2") format("woff2"), url("/public/fonts/velhotes-Bold-Reg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/** =RENDERING **/
html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -moz-font-feature-settings: "smcp=0, onum=0, frac=0, kern=0, liga=0, dlig=0, swsh=0";
  -ms-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -o-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -webkit-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  font-feature-settings: normal;
}

/* Chrome doesn't assume text rendering for form elements */
input,
textarea,
select,
button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -moz-font-feature-settings: "smcp=0, onum=0, frac=0, kern=0, liga=0, dlig=0, swsh=0";
  -ms-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -o-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  -webkit-font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
  font-feature-settings: "smcp" 0, "onum" 0, "frac" 0, "kern" 0, "liga" 0, "dlig" 0, "swsh" 0;
}
.text-subs,
.sub,
sub {
  -moz-font-feature-settings: "subs" 1;
  -moz-font-feature-settings: "subs=1";
  -ms-font-feature-settings: "subs" 1;
  -o-font-feature-settings: "subs" 1;
  -webkit-font-feature-settings: "subs" 1;
  font-feature-settings: "subs" 1;
}
.text-sups,
.sup,
sup {
  -moz-font-feature-settings: "sups" 1;
  -moz-font-feature-settings: "sups=1";
  -ms-font-feature-settings: "sups" 1;
  -o-font-feature-settings: "sups" 1;
  -webkit-font-feature-settings: "sups" 1;
  font-feature-settings: "sups" 1;
}
.sub,
sub,
.sup,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}
.sup,
sup {
  top: -0.5em;
}
.sub,
sub {
  bottom: -0.25em;
}

/** =WEIGHT STYLE **/
strong,
.strong {
  font-family: "#", Helvetica, Arial, sans-serif;
}

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
4. EASINGS
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

/*-----------------------------------------------------------------------
LAYOUT

* @Version:    1.0 - 2016
* @author:     Burocratik
* @email:      hello@burocratik.com
* @website:    http://www.burocratik.com

-----------------------------------------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100%;
  height: auto;
  font-family: "#", Helvetica, Arial, sans-serif;
  color: #ffffff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

.mobile {
  html,
  body {
    overflow: auto;
  }
}

html.fixed-all,
body.fixed-all,
html.modal-open,
body.modal-open,
html.open-nav,
body.open-nav {
  overflow: hidden;
}
body.js-byrefresh {
  opacity: 0;
  background-color: #ffffff;
}
.no-js body.js-byrefresh {
  opacity: 1;
}

.scroll-content-wrapper {
  position: relative;
  &.js-scroll {
    width: 100vw;
    height: 100vh;
  }
}
.scrollbar-track {
  user-select: none;
}

/*-------------------------------------------------------------------------
=CONTENT WRAPPER and =PAGE TRANSITIONS
NOTE: .page-main - if i use translatez menu not fixes on chrome! use will change!
-------------------------------------------------------------------------*/
.page-main {
  position: relative;
  z-index: 0;
  width: 100%;
  /*min-height: 100vh;*/
  /*will-change: transform; > DO not use if i have fixed elements*/
  transform-origin: top center;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden; /* needed when animate elements outside the document*/
}

.page-next,
.page-prev {
  /* prevent paint with values = zero */
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  min-height: 0;
  will-change: transform;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  visibility: hidden;
}
.js-loading-page .page-next,
.js-loading-page .page-prev {
  width: 100%;
  height: auto;
  min-height: 100%;
  visibility: visible;
}

.preload,
.preload-hold {
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: hidden;
  visibility: hidden;
}
/* =loading (fade) page em js is off */
#loading-page {
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  display: none;
  width: 0;
  height: 0;
}
.js-loading-page {
  cursor: wait;
}
#loading-page.js-loading-page {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
#loading-page-legado {
  position: fixed;
  z-index: 5001;
  left: 0;
  top: 0;
  display: none;
  width: 0;
  height: 0;

  // &.js-loading-page {
  //   display: flex;
  //   width: 100%;
  //   height: 100%;
  // }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #faf7ec;

    transition: transform 0.7s ease(out-expo);
    -webkit-transition: -webkit-transform 0.7s ease(out-expo);
  }

  .velhotes-logo-loading {
    position: absolute;
    z-index: 1;
    top: calc(50% - 35px);
    width: 200px;

    transform: scale(1.4);
    -webkit-transform: scale(1.4);

    transition: transform 1s ease(out-expo);
    -webkit-transition: -webkit-transform 1s ease(out-expo);

    .logo-front {
      fill: $black;
    }
    .logo-shadow {
      fill: #86754d;
    }
    .letters {
      fill: #85754d;
    }
  }
}

/*-------------------------------------------------------------------------
=IMAGES and =ICONS
-------------------------------------------------------------------------*/
img {
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
}

/*-------------------------------------------------------------------------
  =MODAL =LIGHTBOX
-------------------------------------------------------------------------*/
#modal-bg,
#modal-wrapper {
  display: none;
  width: 0;
  height: 0;
}
.modal-open #modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background-color: #281a13;
  opacity: 0.8;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

/*-------------------------------------------------------------------------
  =RESET STYLES and GENERAL CLASSES
-------------------------------------------------------------------------*/

/** LINKS , COLORS, SELECTIONS, IMGS **/
a {
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: none;
}
a[href^="tel:"] {
  cursor: default;
}
::-moz-selection {
  background: $black;
  color: #ffffff;
}
::selection {
  background: $black;
  color: #ffffff;
}

/** =LIKE BACKGROUND COVER **/
.block-bg-cover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* important can't use translate 3d with object-fit cover */
.block-bg-cover .element-cover {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
}
/* Exceptions for Firefox */
.firefox .block-bg-cover .element-cover {
  max-width: none;
  width: auto;
}

/** =FORMS **/
input:focus,
textarea:focus,
button:focus {
  outline: none;
}
:invalid,
:-moz-ui-invalid {
  box-shadow: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
button {
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

#hello-world {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  p {
    font-size: 100px;
  }
}

/* — — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — -
6. MIXINS
— — — — — — — — — — — - — — — — — — — — — — — - — — — — — — — — — — — - */

// ----
// libsass (v3.3.6)
// ----

// =========================================================================
//
//  PRECISE CONTROL OVER RESPONSIVE TYPOGRAPHY FOR SASS
//  ---------------------------------------------------
//  Indrek Paas @indrekpaas
//
//  Inspired by Mike Riethmuller's Precise control over responsive typography
//  http://madebymike.com.au/writing/precise-control-responsive-typography/
//
//  `strip-unit()` function by Hugo Giraudel
//
//  11.08.2016 Remove redundant `&` self-reference
//  31.03.2016 Remove redundant parenthesis from output
//  02.10.2015 Add support for multiple properties
//  24.04.2015 Initial release
//
// =========================================================================

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

/* Single property */
// html {
//   @include fluid-type(font-size, 320px, 1366px, 14px, 18px);
// }

/* Multiple properties with same values */
// h1 {
//   @include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
// }
