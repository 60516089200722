@import "reset";

@import "settings";

@import "grid";

@import "media-queries";

@import "layouts/global";

@import "layouts/home";

@import "layouts/meme";

@import "layouts/legal";

@import "layouts/legado";

@import "layouts/covid";

@import "layouts/cocktails";
