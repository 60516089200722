// Body Level
.legado {
  // background: url('/public/imgs/legado/pattern-bruno-v2.png');
  // background-size: 418px 627px;
  background: #faf7ec;


  .velhotes-lightboxes { display: none; }
  .border.top,
  .border.bottom,
  .border.top,
  .border.bottom {
    width: 100vw;
  }

  .border.left,
  .border.right,
  .border.left,
  .border.right {
    height: 100vh;
  }

  .pattern-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: #faf7ec url('/public/imgs/legado/pattern.jpg');
    background-size: 418px 627px;
    mix-blend-mode: multiply;
    opacity: .2;
    pointer-events: none;
    z-index: 1;

    // display: none;
  }

  .pattern-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #faf7ec url('/public/imgs/legado/pattern.jpg');
    background-size: 418px 627px;
    background-blend-mode: multiply;
    pointer-events: none;
    opacity: .5;
    z-index: -1;
  }

  .mobile-bottle-info { display: none; }

  .bottle-parallax-second { opacity: 0; }

  #header-main{
    .block-bg-cover{
      display: none;
    }
    .header-global{
      -webkit-transition: background .25s ease-in-out, box-shadow .25s ease-in-out;
      transition: background .25s ease-in-out, -webkit-box-shadow .25s ease-in-out;

      #create-meme-btn{
        #smile-container{
          svg{
            path{
              stroke: #000;
            }
          }
          #eye{
            border: 2px solid $black;
          }
        }

        h2,
        .char {
          opacity: 0;
          visibility: hidden;
        }
      }

      &.active {
        background: rgba($cream, .97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
      }
    }
    .velhotes-logo{
      opacity: 1;
      visibility: visible;
      .letters{
        display: block;
        &.add-transition {
          -webkit-transition: opacity 1s ease-in-out;
                  transition: opacity 1s ease-in-out;
        }
      }
      .logo-front{
        -webkit-transition: fill 1s ease-in-out;
                transition: fill 1s ease-in-out;
      }
      svg{
        opacity: 1;
        visibility: visible;
      }
    }

    &.active {
      .velhotes-logo{
        .logo-front{
          fill: $black;
        }
        .letters{
          opacity: 0;
        }
      }
    }
  }
}

// Page Level
.legado-page {
  .slogan{
    position: relative;
    z-index: 1;
    font-family: 'velhotes-heavy';
    font-size: 85px;
    line-height: 80px;
    color: $black;
    opacity: 0;
    margin: 240px auto 0 auto;
    max-width: 900px;
    text-transform: uppercase;
    letter-spacing: -1.5px;
    transition: all 2.5s ease(out-expo);
    -webkit-transition: all 2.5s ease(out-expo);
    transform: scale(1.3);
    -webkit-transform: scale(1.3);

    font-feature-settings: "salt";
    -webkit-font-feature-settings: "salt";
    -o-font-feature-settings: "salt";
    -moz-font-feature-settings: "salt";
    -ms-font-feature-settings: "salt";

    &.change-spacing{
      letter-spacing: -1.5px;
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  .page-header {
    position: relative;

    .scroll-helper {
      right: auto;
      left: calc(50% + 6px);
      h2 { color: $black; }
      .arrow-container svg { fill: $black; }
    }

    .images-wrapper {
      .bg { position: relative; }
    }

    h3 {
      position: relative;
      z-index: 1;
      font-family: 'velhotes-roman';
      font-size: 125px;
      line-height: 160px;
      letter-spacing: -10px;
      color: $tawny;
      opacity: .3;
      margin-top: 100px;

      .roman-numb, .high {
        display: block;
        font-size: 310px;
        letter-spacing: 12px;
      }
    }

    p {
      position: relative;
      z-index: 2;
      font-family: 'velhotes-regular';
      font-size: 27px;
      line-height: 27px;
      letter-spacing: -1px;
      color: $dark-brown;
      margin-top: -210px;
    }
  }

  .images-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    // z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &.man {
        top: -100px;
      }

      &.js-image-anime {
        opacity: 0;
      }
    }

    .video-anime {
      width: 100vw;
      height: auto;
    }
  }

  .content-main-wrapper {
    position: relative;
    // padding-top: 300px;
    // margin-top: 700px;
    h3 {
      position: relative;
      z-index: 1;
      font-family: 'velhotes-roman';
      // font-size: 310px;
      font-size: 18vw;
      letter-spacing: -18px;
      color: $tawny;
      opacity: .3;
    }

    p {
      position: relative;
      z-index: 2;
      font-family: 'velhotes-regular';
      font-size: 27px;
      line-height: 32px;
      letter-spacing: -0.5px;
      color: $dark-brown;
    }

    .blockquote {
      font-family: 'velhotes-italic';
      font-size: 45px;
      letter-spacing: -1px;
      color: $tawny;
      margin: 15px 0 25px;
    }

    .arrive {
      .pattern-img { opacity: 0; }
      .images-wrapper {
        // position: relative;
        // margin-top: -385px;
        .man-second,
        .boat {
          top: auto;
          bottom: 180px;
        }
      }
      p {
        margin-top: -297px;
      }
      h3 {
        margin-top: 350px;
      }
    }

    .frame-legacy {
      position: relative;
      // padding-top: 100px;

      // .pattern-img { opacity: .4; }

      .video-anime {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .images-wrapper {
        position: relative;
        z-index: 0;

        .frame-wrapper {
          position: absolute;
          top: calc(50% - 150px);
          left: 50%;
                  transform: translate3d(-50%, -50%, 0);
          -webkit-transform: translate3d(-50%, -50%, 0);
        }

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          z-index: 1;
          margin-top: 61px;
          margin-left: 76px;
          pointer-events: none;
        }

        img {
          width: auto;

          &.mesa-isolated,
          &.book-isolated {
            display: none;
            max-width: 995px;
            width: 100%;
          }

          &.velhotes {
            display: none;
            max-width: 840px;
            width: 100%;
            top: auto;
            bottom: 64px;
            left: 76px;
          }

          &.frame-isolated {
            position: relative;
            top: auto;
            left: auto;
            max-width: 995px;
            // width: 100%;
            height: auto;
            margin: 0 auto;
          }

          &.shadow-isolated {
            position: relative;
            top: -150px;
            left: auto;
            max-width: 1375px;
            width: 100%;
            height: auto;
            margin: 0 auto;
          }
        }

        .bg { position: relative; }

        .left-man-area {
          position: absolute;
          z-index: 2;
          top: 30%;
          left: 5%;
          width: 270px;
          height: 420px;
          cursor: pointer;

          // opacity: .5;
          // background-color: red;
        }

        .middle-man-area {
          position: absolute;
          z-index: 2;
          top: 15%;
          left: 32%;
          width: 480px;
          height: 526px;
          cursor: pointer;

          // opacity: .5;
          // background-color: blue;
        }

        .right-man-area {
          position: absolute;
          z-index: 2;
          top: 20%;
          right: 8%;
          width: 237px;
          height: 540px;
          cursor: pointer;

          // opacity: .5;
          // background-color: yellow;
        }
      }

      .frame-text {
        margin-top: -180px;
        position: relative;
        z-index: 1;
      }

      p:last-child {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
      }

      .quote-frame {
        width: 100%;
        max-width: 560px;
        height: 16px;
        opacity: .1;

        &.top {
          margin-top: 35px;
        }
        &.bottom {
          margin-bottom: 25px;
        }

        .bar { stroke: $dark-brown; }
        .element { fill: $dark-brown; }
      }
    }

    .wall-frames-wrapper {
      margin-top: 130px;

      .wall-frame {
        position: relative;
        z-index: 2;
        &.first {
          margin-top: 70px;
        }
        &.middle {
          img { margin: 0 auto; }
        }
        &.last {
          margin-top: 260px;
          img { margin-left: auto; }
        }
      }

      h3 {
        margin-top: -160px;
        opacity: .3;
      }

      p {
        position: relative;
        z-index: 1;
        max-width: 435px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
        margin-top: -80px;
      }
    }
  }

  .page-footer {
    margin-top: 250px;
    .slogan {
      position: relative ;
      z-index: 1;
      line-height: 80px;
      margin-top: 0;
      max-width: 670px;
    }

    img {
      margin: -240px auto 0 auto;

      transform: scale(0);
      -webkit-transform: scale(0);

      transform-origin: center bottom;
      -webkit-transform-origin: center bottom;
    }

    p {
      margin-top: 80px;
      text-align: center;
      font-family: 'velhotes-regular';
      font-size: 19px;
      line-height: 27px;
      color: #c2bfb6;
    }
  }

  .timeline {
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: -250px;
    left: 0;
    // max-width: 1650px;
    width: 100%;
    max-height: 3750px;
    height: 100%;
    pointer-events: none;
    // opacity: .3;

    .stroke {
      stroke: #8e5a30;
      fill: none;
    }

    .fill {
      stroke: none;
      fill: #8e5a30;
    }

    .line-complete {
      opacity: 0;
    }

    .anime-text {
      font-family: 'velhotes-regular';
      font-size: 15px;
      fill: #8e5a30;
    }

    .anime-text {
      opacity: 0;
    }

    .line-full-1 {
      stroke-dasharray: 1453;
    }

    .line-full-2 {
      stroke-dasharray: 1445;
    }

    .line-full-3 {
      stroke-dasharray: 2741;
    }

    .pointer {
      .medium {
        fill: #f5edd3;
      }
      .large {
        fill: #ffffff;
      }
      .small {
        fill: #eab63e;
      }
    }

    .frame-svg,
    .finish-point,
    .middle-point,
    .start-point,
    .amsterdam,
    .vng,
    .line-full,
    .line-dashed {
      opacity: .3;
    }

    .line-dashed {
      opacity: 0;
    }
  }

  .off-box {
    display: none;
    position: fixed;
    bottom: 60px;
    right: 0;
    max-width: 660px;
    width: 100%;
    padding: 60px;
    background-color: #ffffff;

    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);

    h2 {
      font-size: 50px;
      line-height: 45px;
    }

    .feedback-container {
      margin: 40px 0;
    }

    form {
      max-width: 100%;
      margin-bottom: 0;
    }

    .close-box {
      position: absolute;
      top: 35px;
      right: 30px;
      width: 35px;
      height: 15px;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;

        transform: rotate(40deg);
        -webkit-transform: rotate(40deg);

        transition: transform .25s ease-in-out;
        -webkit-transition: -webkit-transform .25s ease-in-out;

        transform-origin: center center;
        -webkit-transform-origin: center center;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 0.5px);
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;

        transform: rotate(-40deg);
        -webkit-transform: rotate(-40deg);

        transition: transform .25s ease-in-out;
        -webkit-transition: -webkit-transform .25s ease-in-out;

        transform-origin: center center;
        -webkit-transform-origin: center center;
      }

      &:hover {
        &:before {
          transform: rotate(30deg);
          -webkit-transform: rotate(30deg);
        }
        &:after {
          transform: rotate(-30deg);
          -webkit-transform: rotate(-30deg);
        }
      }
    }
  }
}

// Media Queries
@include screen($medium, $xxlarge, $orientation: false) {

}

@include screen($xlarge, 2100px, $orientation: false) {  // --- MacBook Pro 15'(.ie) ---

}

@include min-screen($medium) {

}

@include screen(1024px, 1366px, $orientation: portrait) { // --- IPAD Pro ---

}

@include ipad($orientation: landscape) {

}

@include ipad($orientation: portrait) {

}

@include max-screen($xlarge) {

}

@include max-screen($medium) {
  .legado-page {
    .slogan {
      margin-top: 140px;
    }
    .timeline {
      display: none;
    }
    .pattern-bg {
      opacity: .1;
    }
    .page-header {
      height: 100vh;
      h3 {
        font-size: 100px;
        line-height: 140px;
        letter-spacing: -8px;
        margin-top: 50px;

        .roman-numb,
        .high {
          font-size: 260px;
        }
      }
    }
    .content-main-wrapper {
      // margin-top: 35px;

      h3 {
        font-size: 220px;
        margin-top: 100px;
      }

      .arrive {
        // .images-wrapper {
        //   position: absolute;
        //   margin-top: 0;
        // }
        .pattern-img {
          opacity: .15;
        }
        p {
          // margin-top: 0;
        }
        h3 {
          margin-top: 250px;
        }
      }

      .frame-legacy {
        .pattern-img {
          opacity: .1;
        }

        .frame-text {
          margin-top: -100px;
        }
      }

      .wall-frames-wrapper {
        h3 {
          margin-top: -50px;
        }

        p {
          margin-top: -50px;
        }
      }
    }

    .page-footer {
      img {
        transform: scale(.8);
        -webkit-transform: scale(.8);
      }
    }
  }

  .mobile {
    .legado-page {
      .images-wrapper {
        img {
          position: relative;
        }
      }
    }
  }
}

@include max-screen($small) { // --- iPad portrait ---
  .legado-page {
    .timeline {
      display: none;
    }
    .slogan {
      max-width: 700px;
    }
    .page-header {
      h3 {
        margin-top: 150px;
      }
      .images-wrapper {
        img {
          left: -450px;
        }
      }
    }
    .images-wrapper {
      img {
        height: 100vh;
        max-width: none;
        width: auto;
      }
    }
    .content-main-wrapper {
      margin-top: 0;

      .arrive {
        .images-wrapper {
          img {
            left: -200px;
          }
        }
        h3 {
          margin-top: 320px;
          font-size: 220px !important;
        }
        p {
          margin-top: -330px;
        }
      }

      .frame-legacy {
        padding-top: 100px;
        .images-wrapper {
          img {
            left: auto;
            width: 100%;
            height: auto;
          }
        }

        .frame-text {
          margin-top: -80px;
        }
      }

      .wall-frames-wrapper {
        h3 {
          font-size: 170px;
          margin-top: -80px;
        }
        p {
          margin-top: 50px;
        }
        .wall-frame {
          &.first {
            margin-top: 0;
          }
          &.middle {
            margin-top: 100px;
          }
          &.last {
            margin-top: 40px;
            -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            img {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@include max-screen($xsmall) { // --- iPhone 6/6S/7 and plus versions portrait ---
  .legado {
    .border { display: none; }
    #header-main {
      .header-global {
        #create-meme-btn h2#cria-o-meme,
        #create-meme-btn h2#memes-velhotes {
          display: none;
        }
      }
    }
  }

  .legado-page {
    .slogan {
      font-size: 45px;
      line-height: 1;
    }
    .pattern-bg,
    .pattern-img {
      display: none;
    }

    .page-header {
      .scroll-helper { display: none; }

      h3 {
        font-size: 80px;
        opacity: .4;
        letter-spacing: -6px;
        line-height: 110px;
        margin-top: 80px;

        .roman-numb,
        .high {
          font-size: 180px;
        }
      }

      p {
        margin-top: 50px;
      }

      .images-wrapper {
        img {
          height: 100vh;
          left: -350px;
        }
      }
    }

    .content-main-wrapper {
      h3 {
        font-size: 180px;
      }
      .arrive {
        .images-wrapper {
          top: 150px;
          img {
            height: 100vh;
            left: -80px;
          }
        }

        p {
          margin-top: 350px;
          margin-bottom: 80px;
        }

        h3 {
          margin-top: 200px;
        }
      }

      .frame-legacy {
        padding-top: 50px;
        .frame-text {
          margin-top: 0;
        }

        .quote-frame {
          width: 560px;
          opacity: .2;
          left: calc(50% - 280px);
          position: relative ;
        }

        .images-wrapper {
          img {
            transform: scale(1.3);
            -webkit-transform: scale(1.3);
          }
        }
      }

      .wall-frames-wrapper {
        .wall-frame {
          &.middle {
            margin-top: 40px;
          }
        }

        h3 {
          line-height: 100px;
          margin-top: -50px;
        }

        p { margin-top: 80px; }
      }
    }

    .page-footer {
      margin-top: 150px;

      .slogan {
        font-size: 45px;
        line-height: 1;
      }

      img {
        transform: scale(1);
        -webkit-transform: scale(1);
        height: 553px;
        margin-top: -40px;
      }
    }
  }
}

@include max-screen($xxsmall) { // --- iPhone 4/4S/5/5S portrait ---

}