html,
body {
  overflow: hidden;
}

[data-scrollbar] .scrollbar-thumb,
[scrollbar] .scrollbar-thumb,
scrollbar .scrollbar-thumb {
  width: 5px !important;
  background: #8f5d35 !important;
  border-radius: 0 !important;
}

.firefox {
  #header-main,
  .frame {
    .block-bg-cover {
      img {
        width: 100%;
      }
    }
  }
  .home-page .content-main-wrapper #bottles-container .bottles-list-container {
    right: 0;
    overflow: hidden;
    .bottles-list {
      left: 40px;
    }
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

#meme-submition-confirmation {
  width: 100px;
  height: 100px;
  z-index: 100000000000000;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  button {
    color: #000;
  }
}

.mobile-align {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  &.collapse > .column,
  &.collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  &.collapse > .column > .row,
  &.collapse > .columns > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

body {
  [data-scrollbar] .scrollbar-track-y,
  [scrollbar] .scrollbar-track-y,
  scrollbar .scrollbar-track-y {
    // top: 104px;
    right: 0;
    width: 5px;
    z-index: 1001;
  }

  [data-scrollbar] .scrollbar-thumb,
  [scrollbar] .scrollbar-thumb,
  scrollbar .scrollbar-thumb {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
}

*[data-color="tawny"] {
  color: $tawny;
  &:before {
    background: $tawny;
  }
}
*[data-color="special"] {
  color: $special;
  &:before {
    background: $special;
  }
}
*[data-color="tenYears"] {
  color: $tenYears;
  &:before {
    background: $tenYears;
  }
}
*[data-color="ruby"] {
  color: $ruby;
  &:before {
    background: $ruby;
  }
}
*[data-color="lbv"] {
  color: $lbv;
  &:before {
    background: $lbv;
  }
}
*[data-color="whiteWine"] {
  color: $whiteWine;
  &:before {
    background: $whiteWine;
  }
}
*[data-color="lagrima"] {
  color: $lagrima;
  &:before {
    background: $lagrima;
  }
}

.max-w {
  max-width: 1100px;
}

.border {
  background: #fff3dc;
  position: fixed;
  z-index: 1001;
  &.top,
  &.bottom {
    height: 5px;
    width: 0;
  }
  &.left,
  &.right {
    width: 5px;
    height: 0;
  }
  &.top {
    top: 0;
    left: 0;
  }
  &.right {
    top: 0;
    right: 0;
    z-index: 999;
  }
  &.bottom {
    bottom: 0;
    right: 0;
  }
  &.left {
    bottom: 0;
    left: 0;
  }
}

.bottle-fixed {
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
  img {
    height: 90vh;
    margin: 0 auto;
  }
}

.global-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000000;
  display: none;

  width: 100vw;
  height: 100vh;

  background-color: rgba($black, 0.8);
}

.ie,
.edge {
  .velhotes-lightboxes
    .create-meme-wrapper
    .create-meme-container
    .create-meme-text-wrapper
    .create-meme-text-content
    .meme-generator-form
    .input-container
    svg {
    position: relative;
  }
  #header-main {
    .header-global {
      .homepage-link {
        svg {
          path {
            stroke-dashoffset: 0;
          }
        }
      }
      #create-meme-btn {
        #smile-container {
          &.openSmile {
            svg {
              path {
                stroke-dashoffset: 0;
              }
            }
          }
        }
      }
    }
  }
  .scroll-content-wrapper {
    overflow: hidden;
  }
  .home {
    .frame {
      opacity: 1;
      visibility: visible;
    }
  }
  .home-page {
    .content-main-wrapper {
      #bottle-mobile {
        display: none;
      }
      #slogan {
        font-size: 65px;
        margin-top: -70vh;
        max-width: 700px;
      }
      .scroll-helper {
        top: 93vh;
      }
    }
  }
  .frame .block-bg-cover .element-cover {
    position: absolute;
    top: -150px;
    left: 0 !important;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    width: 100%;
    height: auto;
  }
}

.ie {
  .archive-memes #header-main,
  .single-memes #header-main {
    .velhotes-logo {
      margin-top: 10px;
    }
  }
  #header-main {
    .header-global {
      position: fixed;
      top: 6px;
    }
    &.active {
      .header-global {
        background-color: rgba($white, 0.97);
        box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
        -webkit-box-shadow: 0px 2px 12px 0px rgba(133, 117, 77, 0.2);
      }
    }
  }
}

#header-main {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  .block-bg-cover {
    opacity: 1;
  }

  text-align: center;
  .header-global {
    position: relative;
    z-index: 9;

    width: 100vw;
    // padding: 20px 0;
    padding: 40px 53px;

    // transform: translateY(25px);
    // -webkit-transform: translateY(25px);
    .homepage-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin-left: calc(0.625rem * 3);
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      &.showHouse {
        svg {
          path {
            animation: openSmile 1.4s ease(in-expo) forwards;
          }
        }
      }
      svg {
        width: 26px;
        height: 26px;
        margin-top: -5px;
        path {
          fill: none;
          stroke: $black;
          stroke-miterlimit: 10;
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
        }
      }
      h2 {
        font-family: "velhotes-roman";
        font-size: 15px;
        letter-spacing: 2.5px;
        color: $black;
        text-transform: uppercase;
        overflow: hidden;
        padding-left: 15px;
        opacity: 0;
        visibility: hidden;
        .char {
          transform: translateY(-100%);
          -webkit-transform: translateY(-100%);
          display: inline-block;

          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .offmenu-btn {
      position: relative;
      // margin-left: calc(0.625rem * 3);
      z-index: 4;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      .burguer-wrapper {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 35px;
        height: 15px;
        margin-right: 15px;

        &.hidden {
          .burguer {
            transition: none;
            -webkit-transition: none;
          }
        }

        .burguer {
          position: absolute;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $black;

          transition: transform 1s ease(out-expo);
          -webkit-transition: -webkit-transform 1s ease(out-expo);

          transform-origin: center center;
          -webkit-transform-origin: center center;

          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: calc(50% - 0.5px);
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }

        &.hidden {
          overflow: hidden;

          .burguer {
            opacity: 0;
            visibility: hidden;
            transform: translate3d(-100%, 0, 0);
            -webkit-transform: translate3d(-100%, 0, 0);
          }
        }
      }

      .text-wrapper {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 60px;
        font-family: "velhotes-roman";
        font-size: 15px;
        letter-spacing: 2.5px;
        color: $black;
        text-transform: uppercase;
        overflow: hidden;
        text-align: left;

        .text.close {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
        }

        .char {
          transform: translateY(-100%);
          -webkit-transform: translateY(-100%);
          display: inline-block;

          opacity: 0;
          visibility: hidden;
        }
      }

      &.go-light {
        .burguer-wrapper {
          .burguer {
            background-color: $white;
          }
        }
        .text-wrapper {
          color: $white;
        }
      }
    }

    .offmenu {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;

      .outside-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        height: 100vh;
        background-color: rgba($wine, 0.9);
        pointer-events: visible;
        opacity: 0;
        visibility: hidden;
      }

      .outside-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        max-width: 45vw;
        width: 100%;
        height: 100vh;
        pointer-events: visible;
        opacity: 0;
        visibility: hidden;

        background: #fff4dc;
        background: -moz-linear-gradient(
          -45deg,
          #fff4dc 0%,
          #e4cea3 60%,
          #fff4dc 100%
        );
        background: -webkit-linear-gradient(
          -45deg,
          #fff4dc 0%,
          #e4cea3 60%,
          #fff4dc 100%
        );
        background: linear-gradient(
          135deg,
          #fff4dc 0%,
          #e4cea3 60%,
          #fff4dc 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff4dc', endColorstr='#fff4dc',GradientType=1 );

        transition: transform 0.7s ease(out-expo);
        -webkit-transition: -webkit-transform 0.7s ease(out-expo);
      }

      .anime-bg-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        max-width: 45vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        .anime-bg {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          max-width: 45vw;
          width: 100%;
          height: 100vh;
          background-color: $wine;

          transform: translate3d(-100%, 0, 0);
          -webkit-transform: translate3d(-100%, 0, 0);

          transition: transform 1s ease(out-expo);
          -webkit-transition: -webkit-transform 1s ease(out-expo);
        }
      }

      > .row {
        height: 100%;
      }

      .main-navigation {
        position: relative;
        z-index: 2;
        max-width: 45vw;
        width: 100%;
        pointer-events: visible;
        opacity: 0;
        visibility: hidden;

        li {
          display: block;
          text-align: left;
          margin-bottom: 40px;

          &:nth-child(3) {
            margin-bottom: 50px;
          }

          &:nth-child(n + 3) {
            margin-bottom: 0;
          }

          .nav-link {
            position: relative;
            display: inline-block;
            font-family: "velhotes-roman";
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #2a1c1c;

            .top-text {
              display: block;
              font-size: 20px;
              line-height: 24px;
            }

            .bottom-text {
              position: relative;
              display: block;
              font-size: 45px;
              line-height: 45px;
            }

            .bar-wrapper {
              position: absolute;
              top: calc(50% - 4px);
              left: -7px;
              width: calc(100% + 10px);
              height: 4px;
              overflow: hidden;

              .bar {
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;

                &:nth-child(1) {
                  top: 0;
                  background-color: #2a1c1c;

                  transition: transform 1s ease(out-expo);
                  -webkit-transition: -webkit-transform 1s ease(out-expo);

                  transform: translate3d(-100%, 0, 0);
                  -webkit-transform: translate3d(-100%, 0, 0);
                }
                &:nth-child(2) {
                  bottom: 0;
                  background-color: #ebd7b1;

                  transition: transform 1s ease(out-expo);
                  -webkit-transition: -webkit-transform 1s ease(out-expo);

                  transform: translate3d(100%, 0, 0);
                  -webkit-transform: translate3d(100%, 0, 0);
                }
              }
            }

            &:hover {
              &.wine-shop-link {
                svg {
                  -webkit-transform: translate3d(2px, -2px, 0);
                  transform: translate3d(2px, -2px, 0);
                }
              }

              .bar-wrapper {
                .bar {
                  &:nth-child(1) {
                    transform: translate3d(0%, 0, 0);
                    -webkit-transform: translate3d(0%, 0, 0);
                  }
                  &:nth-child(2) {
                    transform: translate3d(0%, 0, 0);
                    -webkit-transform: translate3d(0%, 0, 0);
                  }
                }
              }
            }

            &.small {
              font-size: 20px;
              line-height: 35px;
              letter-spacing: 2px;
              text-transform: inherit;

              &.wine-shop-link {
                svg {
                  position: absolute;
                  width: 8px;
                  top: 0px;
                  right: -15px;

                  -webkit-transition: -webkit-transform 0.25s ease-in-out;
                  transition: transform 0.25s ease-in-out;

                  path {
                    fill: #2a1c1c;
                  }
                }
              }

              .bar-wrapper {
                width: calc(100% + 12px);
                height: 2px;
                top: calc(50% - 1px);

                .bar {
                  height: 1px;
                }
              }
            }
          }

          &.active {
            .nav-link {
              .bar-wrapper {
                .bar {
                  &:nth-child(1) {
                    transform: translate3d(0%, 0, 0);
                    -webkit-transform: translate3d(0%, 0, 0);
                  }
                  &:nth-child(2) {
                    transform: translate3d(0%, 0, 0);
                    -webkit-transform: translate3d(0%, 0, 0);
                  }
                }
              }
            }
          }
        }
      }

      .sub-navigation {
        position: absolute;
        bottom: 70px;
        left: -32px;
        z-index: 2;
        text-align: left;
        pointer-events: visible;
        opacity: 0;
        visibility: hidden;

        li {
          display: inline-block;
          vertical-align: bottom;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
            margin-left: 18px;
          }
        }
        .facebook {
          width: 9px;
          height: 17px;
          svg {
            width: 9px;
            height: 17px;

            path {
              fill: #2a1c1c;
            }
          }
        }
        .instagram {
          width: 17px;
          height: 17px;
          svg {
            width: 17px;
            height: 17px;

            path {
              fill: #2a1c1c;
            }
          }
        }
        .email {
          width: 18px;
          height: 17px;
          svg {
            width: 18px;
            height: 17px;

            path {
              fill: #2a1c1c;
            }
          }
        }
        .others {
          .local,
          .number {
            display: block;
            font-family: "velhotes-regular";
            font-size: 20px;
            line-height: 24px;
            color: #2a1c1c;
            letter-spacing: 1px;
          }
        }
      }
    }

    .sort-by-btn {
      position: absolute;
      right: 35px;
      top: 28px;
      width: 32px;
      height: 29px;
      display: none;

      svg {
        width: 32px;
        height: 29px;
      }
    }

    .user-container {
      display: flex;
      opacity: 0;
      visibility: hidden;
      .user-wrapper {
        display: inline-flex;
        align-items: center;
      }
      .user-image {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: block;
        border: 1px solid #efefef;
        img {
          border-radius: 50%;
        }

        &:after {
          display: none;
          content: ">";
          position: absolute;
          right: -20px;
          font-size: 20px;
          top: 10px;
          color: #000000;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -webkit-transition: -webkit-transform 0.25s ease;
          transition: transform 0.25s ease;
        }
      }
      h2 {
        font-family: "velhotes-roman";
        font-size: 19px;
        color: $black;
        margin-left: 15px;
        cursor: pointer;
        white-space: nowrap;
      }
      span {
        font-family: "velhotes-regular";
        font-size: 17px;
        color: #4267b2;
        margin-top: 2px;
        margin-left: 15px;
        cursor: pointer;
        transition: 0.6s all ease(out-expo);
        -webkit-transition: 0.6s all ease(out-expo);
      }
    }
    .user-container:not(.mobile) {
      .logout-btn {
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        .logout-btn {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    #create-meme-btn {
      position: relative;
      top: 9px;
      z-index: 1;

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // margin-right: calc(0.625rem * 3);
      opacity: 0;
      visibility: hidden;

      h2 {
        font-family: "velhotes-roman";
        font-size: 15px;
        letter-spacing: 2.5px;
        color: $black;
        text-transform: uppercase;
        overflow: hidden;

        &#cria-o-meme,
        &#memes-velhotes {
          position: absolute;
          top: 50%;
          right: 55px;
          margin: 0 auto;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          white-space: nowrap;
        }
        &#cria-o-meme {
          .char {
            // transform: translateY(-100%);
            // -webkit-transform: translateY(-100%);
            // display: inline-block;
            &:nth-child(4),
            &:nth-child(10) {
              margin-right: 5px;
            }
            &:nth-child(7) {
              margin-left: 5px;
            }
          }
        }
        &#memes-velhotes {
          .char {
            &:nth-child(5) {
              padding-right: 5px;
            }
          }
        }
      }
      .char {
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        display: inline-block;

        opacity: 0;
        visibility: hidden;
      }
      #smile-container {
        position: relative;

        width: 27px;
        height: 25px;
        margin-left: 25px;

        svg {
          position: absolute;
          bottom: 0;
          left: 0;
          path {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            fill: none;
            stroke: #fff;
            stroke-miterlimit: 10;
            stroke-width: 3px;
          }
        }
        #eye {
          position: absolute;
          top: 0;
          left: 0;

          width: 10px;
          height: 10px;

          transform: scale(0);
          -webkit-transform: scale(0);

          transition: 1s transform ease(out-expo);
          -webkit-transition: 1s transform ease(out-expo);

          border: 2px solid $white;
          border-radius: 10px;
        }
        #wink-container {
          position: absolute;
          top: 0;
          right: 0;

          width: 8px;
          height: 8px;
          span {
            position: absolute;
            top: 50%;
            left: 0;

            width: 100%;
            height: 2px;

            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);

            background: $black;
            &:first-of-type {
              top: calc(50% - 0.5px);
              transform: translateY(-50%) rotate(0) scaleX(0);
              -webkit-transform: translateY(-50%) rotate(0) scaleX(0);
              transform-origin: center left;
              -webkit-transform-origin: center left;
            }
            &:last-of-type {
              top: calc(50% + 0.5px);
              -webkit-transform: translateY(-50%) rotate(0) scaleX(0);
              transform: translateY(-50%) rotate(0) scaleX(0);
              transform-origin: center left;
              -webkit-transform-origin: center left;
            }
          }
        }
        &.openSmile {
          svg {
            path {
              animation: openSmile 0.6s ease(in-expo) forwards;
              animation-delay: 0.2s;
            }
          }
        }
        @keyframes openSmile {
          100% {
            stroke-dashoffset: 0;
          }
        }

        @-webkit-keyframes openSmile {
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
      &.openFace {
        #smile-container {
          #eye {
            transform: scale(1);
            -webkit-transform: scale(1);
          }
          #wink-container {
            span {
              transition: 1s transform ease(out-expo);
              -webkit-transition: 1s transform ease(out-expo);
              &:first-of-type {
                transform: translateY(-50%) rotate(0) scaleX(1);
                -webkit-transform: translateY(-50%) rotate(0) scaleX(1);
              }
              &:last-of-type {
                -webkit-transform: translateY(-50%) rotate(0) scaleX(1);
                transform: translateY(-50%) rotate(0) scaleX(1);
              }
            }
          }
        }
      }
    }
  }
  .velhotes-logo {
    width: 200px;
    height: 30px;
    margin: 0 auto;

    font-size: 0;
    text-indent: -9999;
    will-change: transform;

    z-index: 1000000;

    .logo-shadow {
      fill: #86754d;
    }
    .logo-front {
      fill: #fff;
    }
    .letters {
      fill: #85754d;
    }
    svg {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.text-center {
  text-align: center;
}

.filter-container {
  padding: 35px 60px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  width: 410px;

  opacity: 0;
  visibility: hidden;

  .pos-rel {
    position: relative;
  }
  h2 {
    position: relative;
    z-index: 5;
  }
  h2,
  span.active-label {
    font-family: "velhotes-roman";
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #737373;
    display: inline-block;
    white-space: nowrap;
  }
  .filter-wrapper {
    position: absolute;
    top: 2px;
    right: 0;
    height: 70px;
    width: auto;
    overflow: hidden;
    z-index: 4;
    span.active-label {
      position: absolute;
      top: 35px;
      left: 35px;
      white-space: nowrap;
      padding-right: 19px;
      text-align: left;
      color: $black;
      cursor: pointer;
      &:after {
        content: ">";
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: transform 0.6s ease(out-expo);
        transition: transform 0.6s ease(out-expo);
      }
    }
    ul {
      padding: 70px 55px 35px 35px;
      background-color: rgba($white, 0.97);
      box-shadow: 0px 13px 21px 0px rgba(0, 0, 0, 0);
      -webkit-box-shadow: 0px 13px 21px 0px rgba(0, 0, 0, 0);
      display: inline-block;
      li {
        position: relative;
        font-family: "velhotes-roman";
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #737373;
        cursor: pointer;
        margin-bottom: 20px;
        white-space: nowrap;
        &:nth-child(3) {
          margin-bottom: 55px;
        }
        &:hover {
          color: $black;
        }
        &.selected {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
    &.open {
      span.active-label {
        &:after {
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;

          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
    &.add-shadow {
      box-shadow: 0px 13px 21px 0px rgba(0, 0, 0, 0.11);
      -webkit-box-shadow: 0px 13px 21px 0px rgba(0, 0, 0, 0.11);
    }
  }

  &.mobile {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    margin: 0;
    padding: 20px 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.97);

    h2 {
      text-align: left;
      display: block;
      margin-left: 25px;
      margin-bottom: 40px;
    }

    .filter-wrapper {
      position: relative;
      top: auto;
      right: auto;
      height: auto;
      width: 100%;

      ul {
        display: block;
        padding: 0 25px;
        background-color: transparent;
        text-align: left;

        li {
          &.active-label {
            color: #000;
          }
          &:nth-child(3) {
            margin-bottom: 40px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.velhotes-lightboxes {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  z-index: 103;

  background-color: rgba(#333230, 0.9);

  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  opacity: 0;
  visibility: hidden;

  .outside-hitzone {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;
  }

  .login-lightbox,
  .continue-login-lightbox,
  .create-meme-lightbox {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;

    pointer-events: none;
  }

  .create-meme-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    .columns {
      pointer-events: all;
    }

    .row .row {
      margin-left: 0;
      margin-right: 0;
    }

    .close-create-meme {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 60px;
      height: 60px;
      z-index: 1;

      border-radius: 60px;
      text-indent: -9999px;

      background-color: #ffffff;

      -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
      &:hover {
        &:before {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        &:after {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
      &:before,
      &:after {
        transition: 1s transform ease(out-expo);
        -webkit-transition: 1s transform ease(out-expo);
        will-change: transform;
      }
      &:before {
        content: "";
        position: absolute;
        top: 29px;
        left: 17px;
        width: 27px;
        height: 1px;

        background-color: #000000;

        transform: rotate(-45deg) translate3D(0, 0, 0);
        -webkit-transform: rotate(-45deg) translate3D(0, 0, 0);
      }
      &:after {
        content: "";
        position: absolute;
        top: 29px;
        left: 17px;
        width: 27px;
        height: 1px;

        background-color: #000000;

        transform: rotate(45deg) translate3D(0, 0, 0);
        -webkit-transform: rotate(45deg) translate3D(0, 0, 0);
      }
    }

    .create-meme-container {
      position: relative;

      perspective: 1000px;
      box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);
      -webkit-box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);

      .crete-meme-inner-container {
        background-color: #ffffff;
      }
      .save-meme {
        width: 100%;

        font-family: "velhotes-heavy";
        font-size: calc(12px + (20 - 12) * (100vw - 414px) / (1600 - 414));
        text-transform: uppercase;

        padding: 30px 0;

        color: $white;
        background: #3adb51;
        display: block;
        position: absolute;
        bottom: 0;

        .lds-eclipse {
          position: absolute;
          top: 20px;
          left: calc(50% - 100px);
          width: 25px;

          opacity: 0;
        }

        span {
          position: relative;
          display: inline-block;

          -webkit-transition: -webkit-transform 0.3s ease-in-out;
          transition: transform 0.3s ease-in-out;
        }

        &.off {
          background-color: #4d4545;
          color: rgba($white, 0.4);
          pointer-events: none;
        }

        .arrow {
          display: inline-block;
          position: absolute;
          width: 35px;

          top: 2px;
          left: 140px;

          opacity: 0;

          -webkit-transform: translateX(10px);
          transform: translateX(10px);

          -webkit-transition: -webkit-transform 0.3s ease-in-out,
            opacity 0.3s ease-in-out;
          transition: transform 0.3s ease-in-out ease-in-out;
        }

        &:hover {
          .arrow {
            opacity: 1;
            -webkit-transform: translateX(0px);
            transform: translateX(0px);
          }
        }
      }
      .image-wrapper {
        position: relative;
        // padding: 80px;
        height: 100%;
        overflow: hidden;

        -webkit-transform-origin: left top;
        transform-origin: left top;

        .create-meme-image {
          position: relative;
          border: 7px solid #fff4dc;
        }

        #meme-result {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          margin: 0 auto;
          z-index: 10;
          width: 100%;
          height: 100%;
          max-height: calc(100% - 160px);
          max-width: calc(100% - 160px);

          transform: translatey(-50%);
          -webkit-transform: translateY(-50%);

          perspective: 1000px;
          -webkit-perspective: 1000px;

          .block-bg-cover {
            overflow: initial;

            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -webkit-transform-origin: center top 0px;
            transform-origin: center top 0px;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
          }

          #corda-left,
          #corda-right {
            position: absolute;
            top: -145px;
            z-index: 11;
            width: 80px;
            overflow: hidden;

            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
          }

          #corda-left {
            left: 0;
          }

          #corda-right {
            right: 0;
          }

          #canvasMemeGenerator {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            z-index: 0;
            width: 100%;
            border: 7px solid #fff3dc;

            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
          }
        }
      }

      .create-meme-text-wrapper {
        position: relative;
        padding: 50px 80px 100px 80px;

        .phase-2 {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          padding: 50px 80px 0px 80px;

          opacity: 0;
          visibility: hidden;

          .facebook-share-btn {
            display: inline-block;

            padding: 12px 20px;
            margin-bottom: 20px;

            text-align: left;
            font-family: "velhotes-regular";
            font-size: 20px;
            background-color: #4267b2;
            color: #ffffff;
            display: flex;
            align-items: center;

            .icon {
              display: inline-flex;
              svg {
                width: 10px;
                height: 20px;
                path {
                  fill: $white;
                }
              }
            }
            span:not(.icon) {
              margin-left: 20px;

              &.user-name {
                margin-left: 0px;
              }
            }
          }

          h3 {
            margin-bottom: 40px;
          }
        }

        .create-meme-text-header {
          position: relative;

          h2 {
            font-family: "velhotes-heavy";
            font-size: calc(14px + (60 - 14) * (100vw - 414px) / (1600 - 414));
            line-height: 0.85;
            letter-spacing: -1.25px;
            text-transform: uppercase;
            color: $black;
            margin-bottom: 20px;

            font-feature-settings: "salt";
            -webkit-font-feature-settings: "salt";
            -o-font-feature-settings: "salt";
            -moz-font-feature-settings: "salt";
            -ms-font-feature-settings: "salt";
          }
          h3 {
            font-family: "velhotes-regular";
            font-size: calc(12px + (26 - 12) * (100vw - 414px) / (1600 - 414));
            color: #a7a3a3;
            margin-bottom: 35px;
          }
        } //.create-meme-text-header

        .create-meme-text-content {
          position: relative;

          .meme-generator-form {
            margin-top: 15px;
            margin-bottom: 35px;

            .share-on-facebook {
              display: none;
              position: relative;
              margin-top: 30px;

              label {
                font-family: "velhotes-bold-name";
                font-size: 12px;
                color: rgba($black, 0.3);
                letter-spacing: 1.25px;
                text-transform: uppercase;

                position: relative;
                user-select: none;
                cursor: pointer;
                margin-bottom: 0;
                white-space: nowrap;
                #fb-check {
                  opacity: 0;
                  margin-left: -8px;
                }
                input:checked {
                  & + .checkbox {
                    border-color: $black;
                    svg {
                      polyline {
                        stroke-dashoffset: 0;
                      }
                    }
                  }
                }
                .checkbox {
                  position: relative;
                  float: left;
                  width: 20px;
                  height: 20px;
                  border: 2px solid #c8ccd4;
                  svg {
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    polyline {
                      fill: none;
                      stroke: $black;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-dasharray: 18px;
                      stroke-dashoffset: 18px;
                      transition: all 0.3s ease;
                    }
                    > span {
                      pointer-events: none;
                      vertical-align: middle;
                    }
                  }
                }
              }
            }

            .label-input,
            .char-count {
              font-family: "velhotes-bold-name";
              // font-size: calc(12px + (17 - 12) * (100vw - 414px) / (1600 - 414));
              font-size: 13px;
              color: rgba($black, 0.3);
              letter-spacing: 1.25px;
              text-transform: uppercase;
            }
            .char-count {
              float: right;
            }
            .input-container {
              position: relative;
              height: 45px;
              padding-top: 2px;
              input[type="text"] {
                width: 100%;

                font-family: "velhotes-roman";
                font-size: calc(
                  12px + (22 - 12) * (100vw - 414px) / (1600 - 414)
                );
                color: $black;
                padding: 0;
                background: transparent !important;
                letter-spacing: -1px;
              }
              svg {
                position: absolute;
                bottom: 10px;
                left: 0;
                .less-opacity {
                  opacity: 0.2;
                }
                .middle-opacity {
                  opacity: 0.6;
                }
                path {
                  &.with-fill {
                    fill: #202020;
                  }
                }
              }
              &:first-of-type {
                margin-bottom: 35px;
              }
            }
          }

          .antero-text-wrapper {
            margin-top: 35px;
            h4 {
              font-family: "velhotes-bold-name";
              // font-size: calc(12px + (17 - 12) * (100vw - 414px) / (1600 - 414));
              font-size: 13px;
              color: rgba($black, 0.3);
              letter-spacing: 1.25px;
              text-transform: uppercase;
              margin-top: 0;
            }
            p {
              padding-top: 20px;
            }
          }

          #alert-lang {
            font-family: "velhotes-regular";
            font-size: calc(12px + (17 - 12) * (100vw - 414px) / (1600 - 414));
            color: #b24354;
            line-height: 1.2;
            display: block;

            opacity: 0;
            visibility: hidden;
          }

          p {
            margin-bottom: 30px;

            font-family: "velhotes-roman";
            font-size: calc(12px + (22 - 12) * (100vw - 414px) / (1600 - 414));
            color: rgba(#261919, 1);
            letter-spacing: -1px;

            span {
              display: inline-block;
              margin-right: 20px;
              width: 60px;

              font-family: "velhotes-bold";
              font-size: 13px;
              text-transform: uppercase;
              color: rgba(#261919, 0.4);
              text-align: right;
            }
          }
        } //.create-meme-text-content
      }
    }
  }

  .login-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    .columns {
      pointer-events: all;
    }

    .login-container {
      position: relative;
      padding: 70px 80px;

      background-color: #ffffff;
      box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);
      -webkit-box-shadow: 4.5px 7.794px 114px 0px rgba(0, 0, 0, 0.7);
      .close-login,
      .close-continue-login {
        position: absolute;
        top: -30px;
        right: -30px;
        width: 60px;
        height: 60px;
        z-index: 1;

        border-radius: 60px;
        text-indent: -9999px;

        background-color: #ffffff;

        -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
        &:hover {
          &:before {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
          &:after {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
        &:before,
        &:after {
          transition: 1s transform ease(out-expo);
          -webkit-transition: 1s transform ease(out-expo);
          will-change: transform;
        }
        &:before {
          content: "";
          position: absolute;
          top: 29px;
          left: 17px;
          width: 27px;
          height: 1px;

          background-color: #000000;

          transform: rotate(-45deg) translate3D(0, 0, 0);
          -webkit-transform: rotate(-45deg) translate3D(0, 0, 0);
        }
        &:after {
          content: "";
          position: absolute;
          top: 29px;
          left: 17px;
          width: 27px;
          height: 1px;

          background-color: #000000;

          transform: rotate(45deg) translate3D(0, 0, 0);
          -webkit-transform: rotate(45deg) translate3D(0, 0, 0);
        }
      }

      h2 {
        font-family: "velhotes-heavy";
        font-size: calc(40px + (60 - 40) * (100vw - 414px) / (1600 - 414));
        line-height: 0.85;
        letter-spacing: -1.25px;
        text-transform: uppercase;
        color: $black;
        margin-bottom: 20px;

        font-feature-settings: "salt";
        -webkit-font-feature-settings: "salt";
        -o-font-feature-settings: "salt";
        -moz-font-feature-settings: "salt";
        -ms-font-feature-settings: "salt";
      }

      h4 {
        font-family: "velhotes-regular";
        font-size: 26px;
        color: #a7a3a3;
        margin-bottom: 40px;
      }

      p {
        font-family: "velhotes-regular";
        font-size: 17px;
        line-height: 18px;
        color: #000000;
      }
      .politica-privacidade {
        color: #cccccc;

        a {
          color: #cccccc;
          text-decoration: underline;
        }
      }

      .logout-btn {
        font-family: "velhotes-regular";
        font-size: 17px;
        line-height: 18px;
        color: #4267b2;
        margin-top: 20px;
        float: right;
      }

      .facebook-login-btn,
      .facebook-continue-login-btn {
        padding: 12px 20px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 286px;
        text-align: left;
        font-family: "velhotes-regular";
        font-size: 20px;
        background-color: #4267b2;
        color: #ffffff;
        display: flex;
        align-items: center;
        .icon {
          display: inline-flex;
          svg {
            width: 10px;
            height: 20px;
            path {
              fill: $white;
            }
          }
        }
        span:not(.icon) {
          margin-left: 20px;

          &.user-name {
            margin-left: 0px;
          }
        }
      }

      img {
        max-width: 281px;
        border-radius: 100%;
        margin-left: auto;
        border: 4px solid #fff3dc;
      }
    }
  }
}

.scroll-helper {
  position: fixed;
  top: 92vh;
  right: 0px;
  z-index: 1000;

  opacity: 0;
  visibility: hidden;

  transform: rotate(-90deg) translateY(-50%);
  -webkit-transform: rotate(-90deg) translateY(-50%);
  transform-origin: center left;
  -webkit-transform-origin: center left;

  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    font-family: "velhotes-roman";
    font-size: 15px;
    letter-spacing: 2px;
    color: $white;

    margin-left: 12.5px;
    display: inline-block;
  }
  .char {
    opacity: 0;
  }
  .arrow-container {
    width: 10px;
    height: 10px;
    transform: rotate(90deg) translateY(0);
    -webkit-transform: rotate(90deg) translateY(0);
    animation: arrow 3s infinite;
    opacity: 0;
    svg {
      fill: $white;
    }
  }
}

@-webkit-keyframes arrow {
  0% {
    -webkit-transform: rotate(90deg) translateY(0);
  }
  50% {
    -webkit-transform: rotate(90deg) translateY(6px);
  }
  100% {
    -webkit-transform: rotate(90deg) translateY(0);
  }
}

@keyframes arrow {
  0% {
    transform: rotate(90deg) translateY(0);
  }
  50% {
    transform: rotate(90deg) translateY(6px);
  }
  100% {
    transform: rotate(90deg) translateY(0);
  }
}

.ink-mask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 8;

  .bg-layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    -webkit-mask: url("/public/imgs/mask.png");
    -moz-mask: url("/public/imgs/mask.png");
    -ms-mask: url("/public/imgs/mask.png");
    -o-mask: url("/public/imgs/mask.png");
    mask: url("/public/imgs/mask.png");

    -webkit-mask-size: 3000% 100%;
    -moz-mask-size: 3000% 100%;
    -ms-mask-size: 3000% 100%;
    -o-mask-size: 3000% 100%;
    mask-size: 3000% 100%;

    -webkit-mask-position: 0 0;
    -moz-mask-position: 0 0;
    -ms-mask-position: 0 0;
    -o-mask-position: 0 0;
    mask-position: 0 0;

    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    -ms-background-clip: padding;
    background-clip: padding-box;

    background: url(/public/imgs/header-background.jpg) no-repeat center center;
    background-size: cover;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;

    h2 {
      font-family: "velhotes-title";
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 0px;
      color: #85754d;
      margin-bottom: 44px;
      transition: all 2s ease(out-expo);
      -webkit-transition: all 2s ease(out-expo);

      opacity: 0;
    }
    h3 {
      position: relative;

      margin-top: 101px;

      font-family: "velhotes-roman";
      font-size: 65px;
      line-height: 74px;
      letter-spacing: 2.5px;
      color: $white;
      background: -webkit-linear-gradient(
        330deg,
        #fff4dc 0%,
        #e4cea3 25%,
        #fff3dc 50%,
        #e4cea3 75%,
        #fff4dc 100%
      );

      opacity: 0;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      -webkit-transition: letter-spacing 4s ease(out-expo),
        opacity 2s ease(out-expo), -webkit-transform 2s ease(out-expo);
      transition: letter-spacing 4s ease(out-expo), opacity 2s ease(out-expo),
        transform 2s ease(out-expo);

      white-space: nowrap;

      &:before {
        content: "";
        position: absolute;
        top: -48px;
        left: 0;
        right: 0;
        margin: 0 auto;

        width: 0;
        height: 1px;

        background: #85754d;
        transition: width 7s ease(out-expo);
        -webkit-transition: width 7s ease(out-expo);
      }
    }
    .legal-buttons {
      margin-top: 71px;

      opacity: 0;

      -webkit-transition: opacity 2s ease(out-expo);
      transition: opacity 2s ease(out-expo);

      .legal-btn {
        position: relative;
        width: 175px;
        padding: 20px 0;
        pointer-events: all;

        font-family: "velhotes-bold";
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff2db;

        border-top: 2px solid #fff2db;
        border-bottom: 2px solid #fff2db;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background-position: 0 0, 100% 0;
        background-repeat: no-repeat;
        -webkit-background-size: 2px 100%;
        -moz-background-size: 2px 100%;
        background-size: 2px 100%;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=),
          url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzYWNmZDUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzNhNGVkNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
        background-image: -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%),
          -webkit-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
        background-image: -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%),
          -moz-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
        background-image: -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%),
          -o-linear-gradient(top, #fff2db 0%, #e4cea4 100%);
        background-image: linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%),
          linear-gradient(to bottom, #fff2db 0%, #e4cea4 100%);

        transition: 0.4s color ease(out-expo);
        -webkit-transition: 0.4s color ease(out-expo);

        &:first-of-type {
          margin-right: 10px;
        }
        &:last-of-type {
          margin-left: 10px;
        }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background: #fff2db;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;

          transform: scaleY(0);
          -webkit-transform: scaleY(0);

          transform-origin: top left;
          -webkit-transform-origin: top left;

          transition: 0.6s transform ease(out-expo);
          -webkit-transition: 0.6s transform ease(out-expo);
        }

        &:not(.hover-disabled) {
          &:hover {
            color: $background;
            &:after {
              transform: scaleY(1);
              -webkit-transform: scaleY(1);
              transform-origin: bottom left;
              -webkit-transform-origin: bottom left;
            }
          }
        }
      }
    }
    h4 {
      // position: absolute;
      // right: 0;
      // bottom: 59px;
      // left: 0;
      margin: 25px auto 0;

      font-family: "velhotes-regular";
      font-size: 19px;
      line-height: 28px;
      color: rgba($white, 0.1);
      opacity: 0;
      transition: opacity 2s ease(out-expo);
      -webkit-transition: opacity 2s ease(out-expo);
    }
    &.show {
      h2 {
        opacity: 1;
        letter-spacing: 1px;
      }
      h3 {
        letter-spacing: -2.5px;
        opacity: 1;

        &:before {
          width: 105px;
        }
      }
      h4 {
        opacity: 1;
      }
      .legal-buttons {
        opacity: 1;
      }
    }
  }

  &.in {
    .bg-layer {
      -webkit-mask-position: 0 0;
      -moz-mask-position: 0 0;
      -ms-mask-position: 0 0;
      -o-mask-position: 0 0;
      mask-position: 0 0;
      -webkit-animation: mask-to 2s steps(29) forwards;
      animation: mask-to 2s steps(29) forwards;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes mask-to {
  from {
    -webkit-mask-position: 0 0;
    -moz-mask-position: 0 0;
    -ms-mask-position: 0 0;
    -o-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    -moz-mask-position: 100% 0;
    -ms-mask-position: 100% 0;
    -o-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes mask-to {
  from {
    -webkit-mask-position: 0 0;
    -moz-mask-position: 0 0;
    -ms-mask-position: 0 0;
    -o-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    -moz-mask-position: 100% 0;
    -ms-mask-position: 100% 0;
    -o-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;

  height: 100vh;
  width: 100vw;

  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);

  overflow: hidden;
  // background: url(../imgs/velhotes.jpg) no-repeat center center;
  // background-size: cover;
  opacity: 0;
  visibility: hidden;

  // transition: 2s transform ease(out-expo);
  // -webkit-transition: 2s transform ease(out-expo);

  pointer-events: none;
  &.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
  }
}

#bodymovin {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
}

.quote {
  h2 {
    font-family: "velhotes-italic";
    font-size: 42px;
    line-height: 100%;
    letter-spacing: 0px;
    color: $black;

    margin: 250px 0 100px 0;
    transition: transform 5s ease(out-expo);
    -webkit-transition: transform 5s ease(out-expo);
    .first-word {
      position: relative;
      &:before {
        content: "“";

        position: absolute;
        top: 0px;
        left: -45px;

        font-family: "velhotes-roman";
        font-size: 90px;
        color: rgba($black, 0.2);
      }
    }
    .quotation-word {
      position: relative;
      &:after {
        content: "”";

        position: absolute;
        top: 40px;
        right: -45px;

        font-family: "velhotes-roman";
        font-size: 90px;
        color: rgba($black, 0.2);
      }
    }
  }
}

form {
  position: relative;
  max-width: 510px;
  margin: 0 auto;
  input[type="text"],
  input[type="email"] {
    width: 510px;
    font-family: "velhotes-regular";
    font-size: 25px;
    padding: 20px 40px;
    border: none;
    background: #fbf3e2;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;

    width: 80px;
    height: 100%;
    background: #e7d2aa;

    transition: 0.6s transform ease(out-expo);
    -webkit-transition: 0.6s transform ease(out-expo);

    will-change: transform;
    svg {
      position: absolute;
      top: 50%;
      right: calc(40px - 17.5px / 2);

      width: 17.5px;
      height: 27.5px;

      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);

      transition: 0.6s transform ease(out-expo);
      -webkit-transition: 0.6s transform ease(out-expo);
      pointer-events: none;

      path {
        fill: $white;
      }
    }
    &:hover {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
    }
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e7d2aa;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #e7d2aa;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #e7d2aa;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #e7d2aa;
  }
}

footer {
  position: relative;
  padding-bottom: 80px;
  h2,
  h3 {
    font-feature-settings: "salt";
    -webkit-font-feature-settings: "salt";
    -o-font-feature-settings: "salt";
    -moz-font-feature-settings: "salt";
    -ms-font-feature-settings: "salt";
  }
  h2 {
    font-family: "velhotes-heavy";
    font-size: 85px;
    text-transform: uppercase;
    letter-spacing: -1.5px;
    line-height: 70px;
    color: $black;
  }
  h3 {
    font-family: "velhotes-heavy";
    font-size: 25px;
    text-transform: uppercase;
    color: #e7d2aa;
  }
  h4 {
    font-family: "velhotes-regular";
    font-size: 19px;
    color: rgba($black, 0.3);
    // margin-top: 110px;
  }
  a {
    font-family: "velhotes-regular";
    font-size: 19px;
    color: rgba($black, 0.3);
    // margin-top: 10px;
    transition: 0.4s color ease(out-expo);
    -webkit-transition: 0.6s color ease(out-expo);
    &:not(.hover-disabled) {
      &:hover {
        color: rgba(#e7d2aa, 0.7);
      }
    }
  }
  ul {
    display: flex;
    float: right;
    li {
      margin-right: 25px;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        font-family: "velhotes-regular";
        font-size: 19px;
        color: rgba($black, 0.3);
        white-space: nowrap;
      }
    }
  }
  form {
    margin-bottom: 100px;
  }
  .feedback-container {
    position: relative;
    overflow: hidden;
    margin: 70px 0;
    .positive-feedback,
    .subscribe-feedback {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .positive-feedback {
      transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
    }
  }

  .madeby {
    text-align: right;
  }
}

.image-wrap,
.text-wrap {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.tippy-tooltip {
  &.velhotes-tooltip-theme {
    background-color: $white;
    padding: 12px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 0px rgba(46, 48, 53, 0.1);

    .tippy-content {
      display: block;
      color: $black;
      font-size: 15px;
      font-family: "Cera";
      font-weight: bold;
      letter-spacing: -0.5px;
    }
  }
}

.tooltip-theme {
  display: none;
}

.tippy-popper[x-placement^="top"]
  .tippy-tooltip.velhotes-tooltip-theme
  .tippy-arrow {
  border-top-color: $white;
}
.tippy-popper[x-placement^="bottom"]
  .tippy-tooltip.velhotes-tooltip-theme
  .tippy-arrow {
  border-bottom-color: $white;
}

.tippy-tooltip[data-template-id="#tooltip-theme-left"],
.tippy-tooltip[data-template-id="#tooltip-theme-middle"],
.tippy-tooltip[data-template-id="#tooltip-theme-right"] {
  padding: 20px;
  h4 {
    font-family: "velhotes-roman";
    font-size: 32px;
    letter-spacing: -1px;
    color: $tawny;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    font-family: "velhotes-regular";
    font-size: 18px;
    line-height: 19px;
    max-width: 300px;
    font-weight: normal;
    letter-spacing: -0.5px;
    color: #261919;
  }
}

#iubenda-cs-banner {
  bottom: 30px !important;
  left: 0px !important;
  right: 0px !important;
  position: fixed !important;
  width: 40% !important;
  z-index: 99999998 !important;
  margin: 0 auto;
  background-color: rgba($black, 0.95);
  border-radius: 100px;
  padding: 15px 17px 15px 35px;
  @include max-screen($large) {
    width: 50% !important;
  }
  @include max-screen($medium) {
    width: 65% !important;
  }
  @include max-screen($small) {
    width: 70% !important;
  }
  @include max-screen($xsmall) {
    width: 100% !important;
    border-radius: 0;
    bottom: 0 !important;
    padding: 15px;
  }
  p {
    font-size: 14px;
    font-family: "velhotes-regular";
    letter-spacing: 0.1em;
    color: $white;
    line-height: 1em;
    margin-right: 30px;
    letter-spacing: 0;
    @include max-screen($xsmall) {
      margin-right: 15px;
    }
  }
  a {
    color: $white;
    text-decoration: underline;
    text-decoration-color: $white;
  }
  .iubenda-cs-rationale {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
  }
  .iubenda-cs-close-btn {
    text-decoration: none;
    background-color: #da053c;
    padding: 15px 35px;
    border-radius: 35px;
    font-family: "velhotes-regular";
    font-size: 14px;
    position: relative;
    @include max-screen($xsmall) {
      font-size: 0;
      border-radius: 50%;
      text-indent: 9999;
      padding: 15px;
      &:hover {
        background-color: #da053c;
        color: $white;
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 15px;
        height: 1px;
        background-color: $white;
        display: block;
      }
      &:after {
        transform: translateY(-50%) rotate(-45deg);
        -webkit-transform: translateY(-50%) rotate(-45deg);
      }
      &:before {
        transform: translateY(-50%) rotate(45deg);
        -webkit-transform: translateY(-50%) rotate(45deg);
      }
    }
    &:hover {
      background-color: $white;
      color: #da053c;
    }
  }
}

@include max-screen($xlarge) {
  #header-main {
    .velhotes-logo {
      width: 160px;
    }
    .header-global {
      // padding: 10px 0;
    }
  }
  .ink-mask .bg-layer {
    h3 {
      font-size: 55px;
      line-height: 65px;
    }
  }
  .create-meme-lightbox {
    .create-meme-wrapper {
      .create-meme-container {
        .save-meme {
          padding: 20px 0;

          .lds-eclipse {
            top: 5px;
          }
        }
        .create-meme-text-wrapper {
          padding: 30px 60px 100px 60px;
          .create-meme-text-content {
            .meme-generator-form {
              .share-on-facebook {
                p {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include max-screen($large) {
  footer {
    h4 {
      text-align: center;
      margin-bottom: 15px;
    }
    ul {
      float: none;
      margin-bottom: 75px;
      li {
        width: calc(100% / 3);
        text-align: center;
        a {
          display: block;
        }
      }
    }
  }
}

@include max-screen($medium) {
  .login-lightbox,
  .continue-login-lightbox {
    .login-wrapper {
      .login-container {
        padding: 60px;

        h2 {
          font-size: 46px !important;
        }
      }
    }
  }

  .create-meme-lightbox {
    .create-meme-wrapper {
      .close-create-meme {
        top: -10px;
        right: -10px;
      }

      .create-meme-container {
        .create-meme-text-wrapper {
          padding: 40px;
          padding-bottom: 30px;

          .create-meme-text-header {
            h2 {
              font-size: 46px !important;
            }
          }

          .create-meme-text-content {
            .meme-generator-form {
              margin-top: 30px;

              .input-container {
                height: 40px;
              }
            }
          }
        }
      }
    }
  }

  .continue-login-lightbox {
    .login-wrapper {
      .login-container {
        .facebook-continue-login-btn {
          max-width: 100%;
        }
      }
    }
  }

  #header-main {
    .header-global {
      .offmenu {
        .outside-bg {
          max-width: 600px !important;
        }
        .anime-bg-wrapper {
          max-width: 600px !important;

          .anime-bg {
            max-width: 600px !important;
          }
        }
        .main-navigation {
          max-width: 600px !important;

          li {
            margin-bottom: 30px;

            .nav-link {
              .bottom-text {
                font-size: 40px;
                line-height: 40px;
              }

              .top-text {
                font-size: 15px;
                line-height: 15px;
              }
            }
          }
        }

        .sub-navigation {
          left: 0;
          bottom: 40px;

          li {
            &:last-child {
              display: block;
              margin-left: 0;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  footer {
    // h4 {
    //   text-align: left;
    //   margin: 0;
    // }
  }
}

@include max-screen($small) {
  .ink-mask .bg-layer {
    h3 {
      letter-spacing: -2.5px;
      line-height: 65px;
      white-space: normal;
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
    &.show {
      h3 {
        transform: scale(1);
        -webkit-transform: scale(1);
        &:before {
          transition-delay: 0.5s;
          -webkit-transition-delay: 0.5s;
        }
      }
    }
  }

  .login-lightbox,
  .continue-login-lightbox {
    .login-wrapper {
      .login-container {
        img {
          margin: 0 auto 50px;
          max-width: 220px;
        }

        h2 {
          font-size: 52px !important;
        }

        h2,
        h4,
        p {
          text-align: center;
        }

        .facebook-login-btn {
          margin: 0 auto 20px;
          text-align: center;

          .icon {
            margin-left: 20px;
          }
        }

        .facebook-continue-login-btn {
          .icon {
            margin-left: 35px;
          }
        }

        h4 {
          font-size: 22px;
        }

        p {
          max-width: 260px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .create-meme-lightbox {
    .create-meme-wrapper {
      .create-meme-container {
        margin: 50px 0;

        .create-meme-text-wrapper {
          .create-meme-text-header {
            h2 {
              font-size: 56px !important;
            }

            h3 {
              font-size: 26px !important;
            }
          }

          .create-meme-text-content {
            .antero-text-wrapper {
              h4 {
                font-size: 15px;
              }
            }

            .meme-generator-form {
              max-width: 100%;

              label,
              .char-count {
                font-size: 15px;
              }

              .input-container {
                height: 45px;

                input {
                  font-size: 22px !important;
                }
              }
            }

            p {
              font-size: 22px !important;
            }
          }
        }

        .image-wrapper {
          height: 600px;

          #meme-result {
            #corda-right,
            #corda-left {
              width: 65px;
            }
          }
        }

        .save-meme {
          font-size: 24px !important;
        }
      }
    }
  }

  .continue-login-lightbox {
    .login-wrapper {
      .login-container {
        img {
          margin: 0 auto;
        }

        .logout-btn {
          margin-bottom: 30px;
          margin-top: 10px;
        }
      }
    }
  }

  #header-main {
    .header-global {
      .offmenu {
        .main-navigation {
          margin-left: 60px;
        }

        .sub-navigation {
          left: 60px;
          bottom: 60px;

          li {
            &:last-child {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }
}

@include max-screen($xsmall) {
  // --- iPhone 6/6S/7 and plus versions portrait ---
  .nopadd-mob {
    padding: 0;
    margin: 0;
  }
  .ink-mask .bg-layer {
    h2 {
      font-size: 16px;
      margin-bottom: 24px;
      letter-spacing: 1px;
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform-origin: top center;
      -webkit-transform-origin: top center;
    }
    h3 {
      margin-top: 18px;
      white-space: nowrap;
      font-size: 30px;
      letter-spacing: -1.5px;
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform-origin: top center;
      -webkit-transform-origin: top center;
      &:before {
        top: -18px;
      }
    }
    h4 {
      bottom: 30px;
      font-size: 15px;
      line-height: 22px;
      color: rgba($white, 0.2);
    }
    &.show {
      h2 {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
      h3 {
        letter-spacing: -1.5px;
        &:before {
          transition-delay: 0.2s;
          -webkit-transition-delay: 0.2s;
        }
      }
    }
    .legal-buttons {
      margin-top: 10px;
      .legal-btn {
        width: 135px;
        font-size: 14px;
      }
    }
  }
  .quote {
    overflow: hidden;
    h2 {
      font-size: 30px;
      margin-top: 150px;
      margin-bottom: 108px;
      .first-word:before {
        top: -10px;
        left: -30px;
      }
    }
  }
  footer {
    margin-top: 50px;
    h4,
    a {
      font-size: 17px;
    }
    ul {
      float: none;
      margin-bottom: 75px;
      display: block;
      li {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          display: block;
        }
      }
    }
  }

  .filter-container {
    &.mobile {
      display: block;
    }
  }

  #header-main {
    .velhotes-logo {
      width: 160px;
    }

    .header-global {
      padding-left: 0;
      padding-right: 0;
      .user-container {
        &.mobile {
          position: absolute;
          top: 70px;
          width: 100%;
          padding: 8px 0 20px;
          background-color: rgba(255, 255, 255, 0.9);

          .user-wrapper {
            display: block;
            align-items: flex-start;
            text-align: left;
          }

          h2 {
            margin-left: 25px;
            font-size: 22px;
            white-space: nowrap;
          }

          .logout-btn {
            margin-left: 25px;
            font-size: 20px;
            margin-top: 5px;
            display: block;
          }
        }
        .user-image {
          &:after {
            display: block;
          }
        }
      }

      .offmenu-btn {
        .text-wrapper {
          display: none;
        }
      }
    }

    &.js-user-open {
      .header-global {
        .user-container {
          .user-image {
            &:after {
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
              -webkit-transition: -webkit-transform 0.25s ease;
              transition: transform 0.25s ease;
            }
          }
        }
      }
    }
  }

  #header-main {
    .header-global {
      padding-top: 30px;
      .offmenu {
        .outside-bg {
          max-width: 100% !important;
        }
        .anime-bg-wrapper {
          max-width: 100% !important;

          .anime-bg {
            max-width: 100% !important;
          }
        }
        .main-navigation {
          max-width: 100% !important;
          padding-left: 40px;
          margin-left: 0;
          margin-top: -60px;

          li {
            margin-bottom: 20px;
            .nav-link {
              .bottom-text {
                font-size: 28px;
                line-height: 28px;
              }

              .top-text {
                font-size: 12px;
                line-height: 12px;
              }
            }

            &:nth-child(2) {
              margin-bottom: 25px;
            }
          }
        }

        .sub-navigation {
          left: 40px;
          bottom: 30px;
        }
      }

      .offmenu-btn {
        position: absolute;
        left: 35px;
        top: 33px;
        margin: 0;

        .burguer-wrapper {
          margin: 0;
        }
      }

      .create-meme-link {
        position: absolute;
        top: 30px;
        right: 35px;
        #create-meme-btn {
          margin: 0;
          top: 0;
          #smile-container {
            margin: 0;
          }
        }
      }
    }
  }

  .login-lightbox,
  .continue-login-lightbox {
    overflow-x: hidden;
    .login-wrapper {
      height: auto;
      overflow-y: scroll;
      .login-container {
        padding: 40px;
        img {
          max-width: 180px;
        }

        h2 {
          font-size: 46px !important;
        }

        .close-login,
        .close-continue-login {
          top: 20px;
          right: 20px;
          width: 50px;
          height: 50px;

          &:before {
            top: 25px;
            left: 12px;
          }

          &:after {
            top: 25px;
            left: 12px;
          }
        }
      }
    }
  }

  .continue-login-lightbox {
    .login-wrapper {
      .login-container {
        .logout-btn {
          float: none;
          width: 100% !important;
        }
        .facebook-continue-login-btn {
          .icon {
            margin-left: 0;
          }
        }
      }
    }
  }

  .create-meme-lightbox {
    overflow-x: hidden;
    height: 100vh;
    .create-meme-wrapper {
      height: auto;
      overflow-y: scroll;
      overflow-x: hidden;
      .close-create-meme {
        top: 20px;
        right: 20px;
        z-index: 2;
      }

      .create-meme-container {
        margin: 0;

        .create-meme-text-wrapper {
          .create-meme-text-header {
            h2 {
              font-size: 46px !important;
            }
          }

          .create-meme-text-content {
            #alert-lang {
              font-size: 18px !important;
            }
          }
        }

        .image-wrapper {
          height: 400px;
          z-index: 1;

          #meme-result {
            max-width: calc(100% - 80px);
          }
        }

        .save-meme {
          font-size: 18px !important;
          padding: 30px 0;

          .lds-eclipse {
            top: 16px;
            width: 30px;
          }
        }
      }
    }
  }

  .mobile {
    .login-lightbox,
    .continue-login-lightbox,
    .create-meme-lightbox {
      position: fixed;
    }
  }

  .home {
    #header-main {
      .header-global {
        #create-meme-btn h2#cria-o-meme,
        #create-meme-btn h2#memes-velhotes {
          display: none;
        }
      }
    }
  }

  .archive-memes,
  .single-memes {
    #header-main {
      .header-global {
        .sort-by-btn {
          display: block;
          opacity: 0;
          visibility: hidden;
        }
        .create-meme-link {
          position: absolute;
          width: 100%;
          left: 0;
          // bottom: -49px;
          top: 85px;
          background-color: #000;
          opacity: 0;
          visibility: hidden;

          #create-meme-btn {
            justify-content: center;
            margin: 0;
            padding: 12px 0;
            top: auto;

            h2 {
              color: #fff;
            }
            h2#cria-o-meme,
            h2#memes-velhotes {
              right: 130px;
            }

            #smile-container {
              margin-left: 180px;
              -webkit-transform: scale(0.85);
              transform: scale(0.85);
              #eye {
                border-color: #fff;
              }
              #wink-container span {
                background: #fff;
              }
              svg path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    .border {
      display: none;
    }
  }

  .velhotes-lightboxes {
    .create-meme-wrapper {
      .create-meme-container {
        .create-meme-text-wrapper {
          .phase-2 {
            padding: 40px;
            padding-bottom: 30px;
          }
          .create-meme-text-content {
            .meme-generator-form {
              .share-on-facebook {
                // input {
                //   display: inline-block;
                //   width: auto;
                //   vertical-align: top;
                // }

                // p {
                //   display: inline-block;
                //   width: 80%;
                //   vertical-align: top;
                //   font-size: 16px !important;
                //   padding-left: 10px;
                // }
                label {
                  font-size: 16px;
                  white-space: normal;
                  display: block;
                  .checkbox {
                    float: none;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    padding-bottom: 30px;
    h4 {
      text-align: center;
      margin-top: 50px;
    }

    .madeby {
      text-align: center;
    }
  }
}

@include max-screen($xxsmall) {
  form {
    input {
      font-size: 20px;
    }
  }
  .quote {
    h2 {
      font-size: 25px;
      .first-word:before,
      .quotation-word:after {
        font-size: 75px;
      }
    }
  }
  footer {
    h3 {
      font-size: 16px !important;
    }
  }
}
